/* Tooltip container */
/* Tooltip text */
/* Tooltip arrow */
/* Show the tooltip text when you mouse over the tooltip container */
.popoverMUI {
  height: 200px;
  overflow-y: auto;
}
.popoverMUI > div {
  background-color: #ff0000;
  height: 200px;
}
.display-none {
  display: none !important;
}
.tooltip-custom {
  position: relative;
  display: inline-block;
}
.tooltip-custom .tooltiptext-custom {
  visibility: hidden;
  width: 120px;
  background-color: #00B398;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 1s;
}
.tooltip-custom .tooltiptext-custom::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #00B398 transparent transparent transparent;
}
.tooltip-custom:hover .tooltiptext-custom {
  visibility: visible;
  opacity: 1;
}
span.search-results {
  font-size: 13px !important;
  line-height: 30px !important;
  min-height: 25px !important;
}
.alert-dialog-box {
  position: fixed;
  top: 30%;
  width: 50%;
  background-color: #FFFFFF;
  padding: 40px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
span#fiveMinutes {
  font-size: 6em;
  font-weight: 900;
  color: darkred;
}
.alert-dialog-shadow {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 300%;
  background: #00000066;
  z-index: 901;
}
.additional-info-pos {
  width: 100%;
  margin-top: 10px;
  display: inline-flex;
}
.additional-info-element {
  width: 50%;
}
.margin-left-10 {
  margin-left: 10px;
}
.file-upload {
  padding: 11px !important;
}
.form-control-white > div > div {
  left: 10px;
}
.form-control-white > div > input {
  padding: 10px !important;
}
.form-dropdown.form-masked-label:after {
  top: 21px;
  right: 15px;
}
.logged-user {
  text-align: center;
  font-family: DinLight;
  font-size: 13px;
}
.marg-bottom-150 {
  margin-bottom: 150px;
}
.modal-custom {
  display: block;
  position: fixed;
  z-index: 99999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-content-custom {
  background-color: #fefefe;
  margin: 2% auto;
  padding: 20px;
  border: 1px solid #888;
  width: calc(100% - 300px);
  position: relative;
  box-shadow: 20px 20px 50px 0px #424141;
  animation-name: animatetop;
  animation-duration: 0.4s;
}
.close-custom {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  top: 0;
  position: absolute;
  right: 12px;
}
.close-custom:hover {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.close-custom:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.modal-msg-area {
  padding: 20px;
  font-size: 22px;
  word-wrap: break-word;
}
.inline-flex {
  display: inline-flex !important;
}
.compact-position {
  width: 47%;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
}
.compact-position-25 {
  width: 22.5%;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
}
.piciframe {
  position: relative;
}
.save-polygon {
  position: absolute;
  top: 0px;
  right: 0px;
  background: white;
  opacity: 0.8;
  width: 100%;
  height: 656px;
  color: blue;
  font-size: 2em;
  text-align: center;
  line-height: 24;
  z-index: 100000;
}
.lock-map {
  opacity: 0.7;
  pointer-events: none;
}
.lock-mouse {
  pointer-events: none;
}
.help-link {
  cursor: pointer;
  color: #009FE3;
  background-color: transparent;
  margin-left: 10px;
}
.popover {
  position: absolute;
  top: -30px !important;
  left: 24px;
  background-color: #FFFFFF;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  width: 450px !important;
  cursor: default;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.popover:after {
  content: '';
  position: absolute;
  top: 25px !important;
  left: -12px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #FFFFFF;
  width: 0px;
  height: 0px;
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetopalert {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 25%;
    opacity: 1;
  }
}
@media only screen and (max-width: 767px) {
  .back-arrow {
    background: #f5f5f5 !important;
    box-shadow: none !important;
  }
  .additional-info-pos {
    width: 100%;
    display: block;
  }
  .additional-info-element {
    width: 100%;
  }
  .margin-left-10 {
    margin-left: 0;
  }
}
.fullScreenIEBtn {
  cursor: pointer;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  text-decoration: none;
  font-size: 12px;
  position: absolute;
  top: 8px;
  left: 174px;
  background: #53565a;
  font-family: 'Times New Roman', Times, serif;
  height: 30px;
}
.fullScreenIEBtn:hover {
  background-color: #000;
}
.fullScreenIEBtn.lock-map {
  background: #86888b;
}
span.help-btn-text {
  padding: 1px;
  padding-left: 3px;
}
.help-icon {
  width: 17px !important;
  height: 17px !important;
  color: white !important;
}
.help-btn-pos {
  cursor: pointer;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  text-decoration: none;
  font-size: 12px;
  position: absolute;
  top: 9px;
  right: 10px;
  background: #53565a;
  height: 30px;
  z-index: 99999;
}
.help-btn-pos:hover {
  background-color: #000;
}
.help-btn-pos.lock-map {
  background: #86888b;
}
.pos-absolute {
  position: absolute;
}
.icon-alert-triangle-warning {
  stroke: none!important;
  fill: red;
}
.alert-icon-size {
  width: 18px;
  height: 18px;
}
.alerts-grid .alert-icon-info {
  margin-left: 5px;
  width: 14px;
  height: 14px;
  display: inline-block;
  cursor: pointer;
}
.alert-icon-info {
  width: 25px;
}
.alerts-icon {
  width: 25px;
  display: inline-block;
  vertical-align: middle;
}
.alerts-grid {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 10px 5px 5px;
}
.alerts-label {
  display: inline-block;
}
.alerts-grid-order {
  text-align: left;
}
.alert-info-modal .modal-content-custom {
  top: 25%;
  z-index: 9999999999999999999;
  width: 40%;
  left: 30px;
  margin: 1% 0 1% 20%;
  animation-name: animatetopalert;
  animation-duration: 0.7s;
}
.property-plan-modal .modal-content-custom {
  top: 50px;
  z-index: 1;
  width: 50%;
  left: 30px;
  margin: 1% 0 1% 20%;
  animation-name: animatetopalert;
  animation-duration: 0.7s;
}
.basket-msg {
  margin: 20px;
  display: inline-flex;
}
.w20 {
  width: 19%;
}
.w80 {
  width: 79%;
}
.basket-alert-icon-size {
  width: 43px!important;
  height: 43px!important;
}
