/* Severn Trent Searches | by Perceptive Interactive (http://www.perceptiveinteractive.com) in 2017. */
/* Base					==================================*/
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
:focus {
  outline: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html,
body {
  font-size: 100%;
  height: 100%;
  line-height: 1;
  overflow: hidden;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a,
ins,
del {
  text-decoration: none;
}
/* Type					==================================*/
@font-face {
  font-family: 'DinLight';
  src: url('../type/315D61_B_0.eot');
  src: url('../type/315D61_B_0.eot?#iefix') format('embedded-opentype'), url('../type/315D61_B_0.woff2') format('woff2'), url('../type/315D61_B_0.woff') format('woff'), url('../type/315D61_B_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DinBook';
  src: url('../type/315D61_F_0.eot');
  src: url('../type/315D61_F_0.eot?#iefix') format('embedded-opentype'), url('../type/315D61_F_0.woff2') format('woff2'), url('../type/315D61_F_0.woff') format('woff'), url('../type/315D61_F_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DinMedium';
  src: url('../type/315D61_D_0.eot');
  src: url('../type/315D61_D_0.eot?#iefix') format('embedded-opentype'), url('../type/315D61_D_0.woff2') format('woff2'), url('../type/315D61_D_0.woff') format('woff'), url('../type/315D61_D_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DinBold';
  src: url('../type/31E00B_D_0.eot');
  src: url('../type/31E00B_D_0.eot?#iefix') format('embedded-opentype'), url('../type/31E00B_D_0.woff2') format('woff2'), url('../type/31E00B_D_0.woff') format('woff'), url('../type/31E00B_D_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* Images				==================================*/
/* Colors				==================================*/
/* Warm Colors ***/
/* Grey Tints ***/
/* Purple Tints ***/
/* Pink Tints ***/
/* Orange Tints ***/
/* Cool Colors ***/
/* Blue Tints ***/
/* Teal Tints ***/
/* Green Tints ***/
/* Lime Tints ***/
/* Other Colors ***/
/* Appearance			==================================*/
/* Type					==================================*/
#font {
  /*.din-light() {
            font-weight: 300;
        }

        .din-book() {
            font-weight: 400;
        }

        .din-medium() {
            font-weight: 600;
        }

        .din-bold() {
            font-weight: 700;
        }*/
}
/* Gradient				==================================*/
/* Transitions			==================================*/
/*.transform-style(@property: preserve-3d) {
        -webkit-transform-style: @property;
        transform-style: @property;
    }

	

    .rotateX(@degrees) {
        -webkit-transform: rotateX(@degrees);
        -ms-transform: rotateX(@degrees);
        transform: rotateX(@degrees);
    }

    .rotateY(@degrees) {
        -webkit-transform: rotateY(@degrees);
        -ms-transform: rotateY(@degrees);
        transform: rotateY(@degrees);
    }

    .scale3d(@x,@y,@z) {
        -webkit-transform: scale3d(@x,@y,@z);
        -ms-transform: scale3d(@x,@y,@z);
        transform: scale3d(@x,@y,@z);
    }

	.blur(@value) {
		-webkit-filter: blur(@value);
		filter: blur(@value);
	}

    

    .translateY(@y) {
        -webkit-transform: translateY(@y);
        transform: translateY(@y);
    }

    .translateZ(@z) {
        -webkit-transform: translateZ(@z);
        transform: translateZ(@z);
    }

    .translate2d(@x, @y) {
        -webkit-transform: translate(@x, @y);
        transform: translate(@x, @y);
    }

    .translate3d(@x, @y, @z) {
        -webkit-transform: translate3d(@x, @y, @z);
        transform: translate3d(@x, @y, @z);
    }

    .perspective(@value) {
        -webkit-perspective: @value;
        -moz-perspective: 1000;
        -o-perspective: 1000;
        perspective: @value;
    }

    .grayscale(@value) {
        -webkit-filter: grayscale(@value);
        filter: grayscale(@value);
    }*/
/* Flexbox 				==================================*/
/* Dimensions 			==================================*/
/* Elements				==================================*/
/* Headings             ==================================*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1-description,
.h2-description,
.text-big {
  font-family: 'DinLight';
  line-height: 1.2;
}
h1,
.h1 {
  font-family: 'DinBold';
  font-size: 20px;
}
h2,
.h2 {
  font-size: 18px;
}
h3,
.h3 {
  font-size: 16px;
}
.h1-description {
  font-size: 14px;
  color: #989A9C;
}
.h2-description {
  font-size: 13px;
  color: #989A9C;
}
/* iPad Portrait    ==================================*/
@media only screen and (min-width: 768px) {
  h1,
  .h1 {
    font-size: 21px;
  }
  h2,
  .h2 {
    font-size: 17px;
  }
  h3,
  .h3 {
    font-size: 16px;
  }
  .h2-description {
    font-size: 14px;
  }
}
/* Paragraphs & lists   ==================================*/
p {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
p:not(:last-child) {
  margin-bottom: 16px;
}
dl,
ol,
ul {
  font-size: 1em;
  margin: 0 0 1.3em;
  padding: 0;
}
dd,
dt,
li {
  line-height: 1.5;
  margin: 0;
}
/* Inline elements	    ==================================*/
em {
  font-style: italic;
}
strong {
  font-family: 'DinBold';
}
sup {
  vertical-align: super;
  font-size: .5rem;
}
/* Links			    ==================================*/
a {
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
a:not(.button) {
  text-decoration: underline;
}
.text-link {
  color: #009FE3;
}
.text-link:hover,
.text-button:hover {
  color: #E6007E;
}
.text-link:active,
.text-button:active {
  color: #53565A;
}
/* Text Colors	    		==========================*/
/* Warm Colors ***/
.color-grey {
  color: #BABBBD;
}
.color-purple {
  color: #87189D;
}
.color-pink {
  color: #E6007E;
}
.color-orange {
  color: #FFB614;
}
/* Cool Colors ***/
.color-blue {
  color: #33B2E9;
}
.color-teal {
  color: #00B398;
}
.color-green {
  color: #66C088;
}
.color-lime {
  color: #CEDC00;
}
/* Other Colors ***/
.color-black {
  color: #19191B;
}
.color-sts_blue {
  color: #001587;
}
.color-red {
  color: #E90027;
}
.color-residential {
  color: #009FE3;
}
.color-commercial {
  color: #87189D;
}
.color-discount-package {
  color: #E6007E;
}
/* Background Colors        ==========================*/
/* Warm Colors ***/
.background-grey {
  background-color: #F5F5F5;
}
.background-purple {
  background-color: #87189D;
}
.background-pink {
  background-color: #E6007E;
}
.background-orange {
  background-color: #FFB614;
}
/* Cool Colors ***/
.background-blue {
  background-color: #009FE3;
}
.background-teal {
  background-color: #00B398;
}
.background-green {
  background-color: #009639;
}
.background-lime {
  background-color: #CEDC00;
}
/* Other Colors ***/
.background-black {
  background-color: #19191B;
}
.background-sts_blue {
  background-color: #001587;
}
.background-red {
  background-color: #E90027;
}
.background-residential {
  background-color: #CCECF9;
}
.background-commercial {
  background-color: #E7D1EB;
}
.background-discount-package {
  color: #E6007E;
}
/* Alerts                   ==========================*/
.alert {
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.alert .icon {
  width: 32px;
  height: 32px;
}
.alert .alert-content {
  text-align: left;
  width: calc(100% - 46px);
}
.alert ul,
.alert ol {
  margin: 10px 0 0 0;
  list-style: none;
}
.alert ul:not(:last-child),
.alert ol:not(:last-child) {
  margin-bottom: 10px;
}
.alert li {
  text-align: left;
}
.alert li:not(:first-child) {
  padding-top: 5px;
}
.alert li:not(:last-child) {
  padding-bottom: 5px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.alert p {
  margin-bottom: 0;
}
.alert:not(:first-child) {
  margin-top: 20px;
}
.alert:not(:last-child) {
  margin-bottom: 20px;
}
.alert-loading {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
}
.alert-block {
  position: relative;
  padding: 14px;
  border-radius: 3px;
}
.alert-block .icon {
  position: relative;
  z-index: 2;
}
.alert-block .alert-content {
  padding-left: 32px;
}
.alert-block:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 62px;
  z-index: 1;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.alert-block.alert-error {
  box-shadow: inset 0 0 0 1px #E83553;
  background-color: #FDCFD7;
}
.alert-block.alert-error:after {
  background-color: #E83553;
}
.alert-block.alert-warning {
  box-shadow: inset 0 0 0 1px #FFC543;
  background-color: #FFF0D0;
}
.alert-block.alert-warning:after {
  background-color: #FFC543;
}
.alert-block.alert-success {
  box-shadow: inset 0 0 0 1px #33AB61;
  background-color: #CCEAD7;
}
.alert-block.alert-success:after {
  background-color: #33AB61;
}
.alert-block.alert-info {
  box-shadow: inset 0 0 0 1px #33B2E9;
  background-color: #CCECF9;
}
.alert-block.alert-info:after {
  background-color: #33B2E9;
}
.alert-error {
  color: #E90027;
}
.alert-warning {
  color: #ad7700;
}
.alert-success {
  color: #27844b;
}
.alert-info {
  color: #179ad2;
}
.product-alert {
  display: block;
  text-align: center;
  padding: 14px;
  margin: 14px;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #FFC543;
  background-color: #FFF0D0;
}
.product-alert > h3,
.product-alert > em {
  color: #ad7700;
}
.product-alert > h3 {
  text-align: center;
  font-family: 'DinBold';
}
.product-alert table {
  color: #53565A;
  background-color: #FFFFFF;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-top: 14px;
  width: 100%;
}
.product-alert > .button {
  margin-top: 14px;
}
/* Loading                  ==========================*/
.loader {
  position: relative;
  margin: 10px auto;
  width: 24px;
  height: 14px;
}
.loader:after,
.loader:before {
  content: '';
  position: absolute;
  left: 0;
  width: 24px;
  height: 6px;
  -webkit-animation: loader 1s infinite ease-in-out;
  animation: loader 1s infinite ease-in-out;
}
.loader:after {
  top: 0;
  background-color: #001587;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before {
  top: 8px;
  background-color: #009639;
}
.button .loader,
.entry .loader,
.text-link .loader {
  margin: 6px auto;
  width: 16px;
  height: 8px;
}
.button .loader:after,
.entry .loader:after,
.text-link .loader:after,
.button .loader:before,
.entry .loader:before,
.text-link .loader:before {
  width: 16px;
  height: 3px;
}
.button .loader:after,
.entry .loader:after,
.text-link .loader:after {
  background-color: rgba(0, 0, 0, 0.4);
}
.button .loader:before,
.entry .loader:before,
.text-link .loader:before {
  top: 4px;
  background-color: rgba(0, 0, 0, 0.2);
}
/* Tooltip                  ==========================*/
.tooltip {
  display: inline;
  position: relative;
  cursor: help;
}
.tooltip:before,
.tooltip:after {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0, 1.47, 0.565, 1.61), opacity 0.2s ease-out;
  transition: transform 0.2s cubic-bezier(0, 1.47, 0.565, 1.61), opacity 0.2s ease-out;
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  -ms-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9);
}
.tooltip:after {
  content: attr(title);
  left: 50%;
  bottom: 150%;
  text-indent: 0;
  text-align: center;
  font-size: 12px;
  padding: 5px;
  color: #FFFFFF;
  background: #00B398;
  border-radius: 3px;
  z-index: 98;
  margin-left: -40px;
  min-width: 80px;
  max-width: 160px;
}
.tooltip:before {
  content: '';
  left: 50%;
  bottom: 6px;
  border: solid transparent;
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #00B398;
  border-width: 8px;
  margin-left: -6px;
  z-index: 99;
  width: 0;
  height: 0;
}
.tooltip:hover:before,
.tooltip:hover:after {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0, 1.47, 0.565, 1.61), opacity 0.2s ease-out;
  transition: transform 0.2s cubic-bezier(0, 1.47, 0.565, 1.61), opacity 0.2s ease-out;
  transition-delay: .8ms;
  -webkit-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
.button .tooltip {
  cursor: pointer;
}
/* Date Badge               ==========================*/
.date-badge {
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  background-color: #FFFFFF;
  box-shadow: 0 0 0 1px #DDDDDE;
  border-radius: 3px;
  width: 24px;
  height: 24px;
  /* iPad Portrait          ========================*/
}
.date-badge span {
  display: block;
}
.date-badge .month {
  color: #757876;
  background-color: #DDDDDE;
  font-size: 8px;
  line-height: 10px;
}
.date-badge .day {
  color: #BABBBD;
  font-family: 'DinBold';
  font-size: 10px;
  line-height: 14px;
}
.date-badge.color-red {
  box-shadow: 0 0 0 1px #E83553;
  background-color: #FDCFD7;
}
.date-badge.color-red .month {
  color: #FFFFFF;
  background-color: #E83553;
}
.date-badge.color-red .day {
  color: #E90027;
}
.date-badge.color-orange {
  box-shadow: 0 0 0 1px #FFC543;
  background-color: #FFF0D0;
}
.date-badge.color-orange .month {
  color: #FFFFFF;
  background-color: #FFC543;
}
.date-badge.color-orange .day {
  color: #e09a00;
}
.date-badge.color-green {
  box-shadow: 0 0 0 1px #33AB61;
  background-color: #CCEAD7;
}
.date-badge.color-green .month {
  color: #FFFFFF;
  background-color: #33AB61;
}
.date-badge.color-green .day {
  color: #009639;
}
@media only screen and (min-width: 768px) {
  .date-badge {
    width: 32px;
    height: 32px;
  }
  .date-badge .month {
    font-size: 10px;
    line-height: 14px;
  }
  .date-badge .day {
    font-size: 14px;
    line-height: 20px;
  }
}
/* Count Badge              ==========================*/
.badge {
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  color: #FFFFFF;
  background-color: #E90027;
  border-radius: 24px;
  padding-top: 1px;
  margin-left: 3px;
  width: 20px;
  height: 20px;
}
/* Status Label             ==========================*/
.status-label {
  display: inline-block;
  font-size: 10px;
  line-height: 1;
  padding: 5px 10px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 25px;
}
.status-label.received {
  color: #989A9C;
  border: 1px dotted #989A9C;
  background-color: #F5F5F5;
}
.status-label.cancelled {
  color: #757876;
  border: 1px solid #BABBBD;
  background-color: #DDDDDE;
}
.status-label.in-progress {
  color: #33B2E9;
  border: 1px dotted #66C5EE;
  background-color: #CCECF9;
}
.status-label.completed {
  color: #33AB61;
  border: 1px solid #66C088;
  background-color: #CCEAD7;
}
.status-label.warning {
  color: #ad7700;
  border: 1px solid #FFD372;
  background-color: #FFF0D0;
}
.status-label.new {
  color: #009639;
}
.status-label.reference-label {
  font-family: 'DinBold';
  color: #989A9C;
  border: 1px solid #F5F5F5;
  background-color: #F5F5F5;
}
.status-label.primary-label {
  color: #33B2E9;
  border: 1px solid #66C5EE;
  background-color: #CCECF9;
}
.no-content {
  font-family: 'DinLight';
  font-size: 21px;
  text-align: center;
  padding: 20px;
  margin: 0 auto;
}
.no-content .icon {
  stroke: #BABBBD;
  margin: 0 auto 10px;
  width: 42px;
  height: 42px;
}
.bordered {
  border: 4px solid red;
  font-weight: bold;
  padding: 0.2em 0.2em 0.8em 0.2em;
}
/* Core                 ==================================*/
.button {
  display: inline-block;
  font-family: 'DinMedium';
  text-align: center;
  line-height: 20px;
  border: 0;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
.button:hover {
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.4), inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}
.button:active {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}
.button a:not([class*="color-"]) {
  color: #53565A;
}
.button[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: .65;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.button[disabled]:hover,
.button[disabled]:active {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.button-right {
  position: absolute;
  right: 0;
  margin-right: 4%;
}
/* Sizes                 ==================================*/
.button-large {
  font-size: 18px;
  padding: 16px 20px;
}
.button-display {
  font-size: 14px;
  padding: 12.5px 18px;
}
.button-regular {
  font-size: 14px;
  padding: 10px 14px;
}
.button-small {
  font-size: 13px;
  padding: 6px 8px 4px 8px;
}
.button-full-width {
  width: 100%;
}
/* With Icon             ==================================*/
.button-icon {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.button-icon-small {
  width: 30px;
  height: 30px;
}
.button-icon-small .icon {
  width: 14px;
  height: 14px;
}
.button-icon-regular {
  width: 40px;
  height: 40px;
}
.button-icon-regular .icon {
  width: 20px;
  height: 20px;
}
.button-icon-display {
  width: 45px;
  height: 45px;
}
.button-icon-display .icon {
  width: 14px;
  height: 14px;
}
.button-text-icon {
  position: relative;
}
.button-text-icon .icon {
  position: absolute;
  top: 50%;
  left: 5px;
}
.button-text-icon.button-small {
  padding-left: 22px;
}
.button-text-icon.button-small .icon {
  margin-top: -7px;
  width: 14px;
  height: 14px;
}
/* Colors                 ==================================*/
.button-green,
.button-blue,
.button-teal,
.button-red,
.button-purple {
  color: #FFFFFF;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.4);
}
.button-green .icon,
.button-blue .icon,
.button-teal .icon,
.button-red .icon,
.button-purple .icon {
  stroke: #FFFFFF;
}
.button-green:hover,
.button-blue:hover,
.button-teal:hover,
.button-red:hover,
.button-purple:hover {
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.8), inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}
.button-green {
  background-image: linear-gradient(-180deg, #47B543 0%, #008C0E 100%);
  background-color: #2ba52e;
}
.button-green:active {
  background-image: linear-gradient(0deg, #47B543 0%, #008C0E 100%);
  background-color: #2ba52e;
}
.button-blue {
  background-image: linear-gradient(-180deg, #00B1FD 0%, #0086BF 100%);
  background-color: #00a0e4;
}
.button-blue:active {
  background-image: linear-gradient(0deg, #00B1FD 0%, #0086BF 100%);
  background-color: #00a0e4;
}
.button-teal {
  background-image: linear-gradient(-180deg, #70E6D4 0%, #57B3A5 100%);
  background-color: #66d2c1;
}
.button-teal:active {
  background-image: linear-gradient(0deg, #70E6D4 0%, #57B3A5 100%);
  background-color: #66d2c1;
}
.button-red {
  background-image: linear-gradient(-180deg, #F30028 0%, #BF001F 100%);
  background-color: #de0024;
}
.button-red:active {
  background-image: linear-gradient(0deg, #F30028 0%, #BF001F 100%);
  background-color: #de0024;
}
.button-purple {
  background-image: linear-gradient(-180deg, #9B33B0 0%, #860E9E 100%);
  background-color: #9324a9;
}
.button-purple:active {
  background-image: linear-gradient(0deg, #9B33B0 0%, #860E9E 100%);
  background-color: #9324a9;
}
/* Grey, White and Transparent      ========================*/
.button-grey {
  background: #F5F5F5;
}
.button-grey:not([class*="color-"]) {
  color: #53565A;
}
.button-grey:active {
  background: #DDDDDE;
}
.button-white {
  background: #FFFFFF;
}
.button-white:not([class*="color-"]) {
  color: #53565A;
}
.button-white:active {
  background: #F5F5F5;
}
.button-white.color-blue {
  box-shadow: inset 0 0 0 1px #66C5EE;
}
.button-white.color-blue:hover {
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.4), inset 0 0 0 1px #009FE3;
}
.button-white.color-green {
  box-shadow: inset 0 0 0 1px #66C088;
}
.button-white.color-green:hover {
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.4), inset 0 0 0 1px #009639;
}
.button-white.color-pink {
  box-shadow: inset 0 0 0 1px #F066B2;
}
.button-white.color-pink:hover {
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.4), inset 0 0 0 1px #E6007E;
}
.button-white.color-teal {
  box-shadow: inset 0 0 0 1px #66D1C1;
}
.button-white.color-teal:hover {
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.4), inset 0 0 0 1px #00B398;
}
.button-white.color-blue:hover,
.button-grey.color-blue:hover,
.button-transparent.color-blue:hover {
  color: #009FE3;
}
.button-white.color-blue .icon,
.button-grey.color-blue .icon,
.button-transparent.color-blue .icon {
  stroke: #009FE3;
}
.button-white.color-green:hover,
.button-grey.color-green:hover,
.button-transparent.color-green:hover {
  color: #009639;
}
.button-white.color-green .icon,
.button-grey.color-green .icon,
.button-transparent.color-green .icon {
  stroke: #009639;
}
.button-white.color-pink:hover,
.button-grey.color-pink:hover,
.button-transparent.color-pink:hover {
  color: #E6007E;
}
.button-white.color-pink .icon,
.button-grey.color-pink .icon,
.button-transparent.color-pink .icon {
  stroke: #E6007E;
}
.button-white.color-teal:hover,
.button-grey.color-teal:hover,
.button-transparent.color-teal:hover {
  color: #00B398;
}
.button-white.color-teal .icon,
.button-grey.color-teal .icon,
.button-transparent.color-teal .icon {
  stroke: #00B398;
}
.button-transparent {
  background-color: transparent;
  box-shadow: none;
}
.button-transparent:active,
.button-transparent:hover {
  box-shadow: none;
}
/* Core UI Icons        ==================================*/
.icon {
  display: block;
  stroke: #53565A;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  width: 24px;
  height: 24px;
}
.icon-eye-closed,
.icon-search,
.icon-calendar {
  stroke: #BABBBD;
}
.icon-clear,
.icon-trash {
  stroke: #E83553;
}
.icon-alert-error {
  stroke: #a4132b;
}
.icon-alert-warning {
  stroke: #ad7700;
}
.icon-alert-success {
  stroke: #007d2f;
}
.icon-alert-info {
  stroke: #008dca;
}
.icon-attention-residential {
  stroke: #009FE3;
}
.icon-attention-commercial {
  stroke: #87189D;
}
.icon-attention-package {
  stroke: #E6007E;
}
.icon-reply {
  -webkit-transform: rotate(-180deg) scaleY(-1);
  transform: rotate(-180deg) scaleY(-1);
}
.icon-arrow-down {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-arrow-up {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.icon-star {
  stroke: #BABBBD;
  fill: none;
}
.icon-star.star-grey {
  stroke: #BABBBD;
  fill: #BABBBD;
}
.icon-document {
  stroke: none;
}
/* IE10+            ==================================*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .icon {
    background-size: contain;
  }
  .icon-eye-open {
    background-image: url('../img/icons-ie.svg#eye-open');
  }
  .icon-eye-closed {
    background-image: url('../img/icons-ie.svg#eye-closed');
  }
  .icon-alert-error {
    background-image: url('../img/icons-ie.svg#alert-error');
  }
  .icon-alert-warning {
    background-image: url('../img/icons-ie.svg#alert-warning');
  }
  .icon-alert-success {
    background-image: url('../img/icons-ie.svg#alert-success');
  }
  .icon-search {
    background-image: url('../img/icons-ie.svg#search');
  }
  .icon-calendar {
    background-image: url('../img/icons-ie.svg#calendar');
  }
  .icon-clear {
    background-image: url('../img/icons-ie.svg#clear');
  }
  .icon-attention-residential {
    background-image: url('../img/icons-ie.svg#attention-residential');
  }
  .icon-attention-commerical {
    background-image: url('../img/icons-ie.svg#attention-commerical');
  }
  .icon-trash {
    background-image: url('../img/icons-ie.svg#trash');
  }
  .icon-download {
    background-image: url('../img/icons-ie.svg#download');
  }
  .icon-tab-order {
    background-image: url('../img/icons-ie.svg#order-archive');
  }
  .icon-tab-query {
    background-image: url('../img/icons-ie.svg#query-archive');
  }
  .icon-tab-contact {
    background-image: url('../img/icons-ie.svg#contact');
  }
  .icon-tab-address {
    background-image: url('../img/icons-ie.svg#address');
  }
  .icon-tab-settings {
    background-image: url('../img/icons-ie.svg#settings');
  }
  .icon-tab-product {
    background-image: url('../img/icons-ie.svg#cart-tab');
  }
  .icon-tab-check {
    background-image: url('../img/icons-ie.svg#check-tab');
  }
  .active .icon-tab-order {
    background-image: url('../img/icons-ie.svg#order-archive-active');
  }
  .active .icon-tab-query {
    background-image: url('../img/icons-ie.svg#query-archive-active');
  }
  .active .icon-tab-contact {
    background-image: url('../img/icons-ie.svg#contact-active');
  }
  .active .icon-tab-address {
    background-image: url('../img/icons-ie.svg#address-active');
  }
  .active .icon-tab-settings {
    background-image: url('../img/icons-ie.svg#settings-active');
  }
  .active .icon-tab-product {
    background-image: url('../img/icons-ie.svg#cart-active');
  }
  .active .icon-tab-check {
    background-image: url('../img/icons-ie.svg#check-active');
  }
  .icon-info {
    background-image: url('../img/icons-ie.svg#info');
  }
  .icon-broken {
    background-image: url('../img/icons-ie.svg#broken');
  }
  .icon-attachment {
    background-image: url('../img/icons-ie.svg#attachment');
  }
  .icon-filter {
    background-image: url('../img/icons-ie.svg#filter');
  }
  .icon-mobile {
    background-image: url('../img/icons-ie.svg#mobile');
  }
  .icon-star {
    background-image: url('../img/icons-ie.svg#star');
  }
  .icon-star.star-grey {
    background-image: url('../img/icons-ie.svg#star-grey');
  }
  .icon-star.favorite {
    background-image: url('../img/icons-ie.svg#star-favorite');
  }
  .icon-cart {
    background-image: url('../img/icons-ie.svg#cart');
  }
  .icon-check {
    background-image: url('../img/icons-ie.svg#check');
  }
  .icon-con29dw {
    background-image: url('../img/icons-ie.svg#con29dw');
  }
  .icon-local-authority {
    background-image: url('../img/icons-ie.svg#local-authority');
  }
  .icon-land-registry {
    background-image: url('../img/icons-ie.svg#land-registry');
  }
  .icon-enviromental {
    background-image: url('../img/icons-ie.svg#enviromental');
  }
  .icon-mining {
    background-image: url('../img/icons-ie.svg#mining');
  }
  .icon-specialist {
    background-image: url('../img/icons-ie.svg#specialist');
  }
  .icon-insurance {
    background-image: url('../img/icons-ie.svg#insurance');
  }
  .icon-chancel {
    background-image: url('../img/icons-ie.svg#chancel');
  }
  .icon-utility {
    background-image: url('../img/icons-ie.svg#utility');
  }
  .icon-pdf {
    background-image: url('../img/icons-ie.svg#pdf');
  }
}
/* Keyframes            ==================================*/
/* fadeIn ***/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}
/* FadeOut ***/
@-webkit-keyframes fadeOut {
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
/* Loading ***/
@-webkit-keyframes loader {
  0%,
  80%,
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  40% {
    -webkit-transform: scale3d(0.2, 1, 2);
    -ms-transform: scale3d(0.2, 1, 2);
    transform: scale3d(0.2, 1, 2);
  }
}
@keyframes loader {
  0%,
  80%,
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  40% {
    -webkit-transform: scale3d(0.2, 1, 2);
    -ms-transform: scale3d(0.2, 1, 2);
    transform: scale3d(0.2, 1, 2);
  }
}
/* Sets                 ==================================*/
/* Layout				==================================*/
/* Core             ==================================*/
::-webkit-selection {
  background: #001587;
  color: #FFFFFF;
}
::-moz-selection {
  background: #001587;
  color: #FFFFFF;
}
::selection {
  background: #001587;
  color: #FFFFFF;
}
html,
body {
  width: 100%;
}
html {
  -webkit-text-size-adjust: 100%;
}
body {
  font-family: 'DinBook';
  font-size: 14px;
  line-height: 1.4;
  color: #53565A;
  background-color: #F5F5F5;
  overflow-x: hidden;
}
body.no-scroll {
  overflow: hidden;
}
.hidden {
  display: none !important;
}
.hide-mobile,
.hide-tablet,
.show-desktop {
  display: none;
}
.content {
  position: relative;
  background-color: #FFFFFF;
  /*z-index: 10;*/
  top: 0px;
}
.user {
  display: block;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'DinLight';
  font-size: 13px;
  padding: 20px 0;
  width: 100%;
}
.user strong {
  display: block;
  width: 100%;
}
.cart-toggle {
  display: none;
}
/* iPad Portrait             ==================================*/
@media only screen and (min-width: 768px) {
  body {
    background: #CCECF9 url('../img/blueprint-big.png') repeat;
    background-size: cover;
  }
  .hide-mobile {
    display: inherit;
  }
  .show-mobile {
    display: none;
  }
  .content {
    position: relative;
    background-color: transparent;
    /*padding-bottom: 80px;*/
    /*#calc > #min-height > .remove(100vh, 170px);*/
  }
  .user {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
/* Up to iPad Landscape    ============================*/
@media only screen and (max-width: 1023px) {
  .cart-toggle {
    display: inherit;
  }
}
/* iPad Landscape            ==================================*/
@media only screen and (min-width: 1024px) {
  .hide-tablet {
    display: inherit;
  }
  .show-tablet {
    display: none;
  }
}
/* Desktop          ==================================*/
@media only screen and (min-width: 1320px) {
  .show-desktop {
    display: inherit;
  }
  .content-wrapper {
    margin: 0 auto;
    max-width: 1280px;
  }
}
/* IE10+                     ==================================*/
/* Core                 ==================================*/
table {
  border-collapse: collapse;
  width: 100%;
  /* Cells can go from 10px to 600px ***/
}
table th,
table td {
  text-align: left;
  padding: 6px 9px 6px 3px;
}
table th.align-right,
table td.align-right {
  text-align: right;
}
table th.align-center,
table td.align-center {
  text-align: center;
}
table th:first-child,
table td:first-child {
  padding-left: 9px;
}
table th {
  font-family: 'DinMedium';
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
  vertical-align: middle;
}
table th span:not(.tooltip) {
  color: #BABBBD;
}
table td {
  vertical-align: top;
}
table .cell-10 {
  width: 10px;
}
table .cell-20 {
  width: 20px;
}
table .cell-30 {
  width: 30px;
}
table .cell-40 {
  width: 40px;
}
table .cell-50 {
  width: 50px;
}
table .cell-60 {
  width: 60px;
}
table .cell-70 {
  width: 70px;
}
table .cell-80 {
  width: 80px;
}
table .cell-90 {
  width: 90px;
}
table .cell-100 {
  width: 100px;
}
table .cell-110 {
  width: 110px;
}
table .cell-120 {
  width: 120px;
}
table .cell-130 {
  width: 130px;
}
table .cell-140 {
  width: 140px;
}
table .cell-150 {
  width: 150px;
}
table .cell-160 {
  width: 160px;
}
table .cell-170 {
  width: 170px;
}
table .cell-180 {
  width: 180px;
}
table .cell-190 {
  width: 190px;
}
table .cell-200 {
  width: 200px;
}
table .cell-210 {
  width: 210px;
}
table .cell-220 {
  width: 220px;
}
table .cell-230 {
  width: 230px;
}
table .cell-240 {
  width: 240px;
}
table .cell-250 {
  width: 250px;
}
table .cell-260 {
  width: 260px;
}
table .cell-270 {
  width: 270px;
}
table .cell-280 {
  width: 280px;
}
table .cell-290 {
  width: 290px;
}
table .cell-300 {
  width: 300px;
}
table .cell-310 {
  width: 310px;
}
table .cell-320 {
  width: 320px;
}
table .cell-330 {
  width: 330px;
}
table .cell-340 {
  width: 340px;
}
table .cell-350 {
  width: 350px;
}
table .cell-360 {
  width: 360px;
}
table .cell-370 {
  width: 370px;
}
table .cell-380 {
  width: 380px;
}
table .cell-390 {
  width: 390px;
}
table .cell-400 {
  width: 400px;
}
table .cell-410 {
  width: 410px;
}
table .cell-420 {
  width: 420px;
}
table .cell-430 {
  width: 430px;
}
table .cell-440 {
  width: 440px;
}
table .cell-450 {
  width: 450px;
}
table .cell-460 {
  width: 460px;
}
table .cell-470 {
  width: 470px;
}
table .cell-480 {
  width: 480px;
}
table .cell-490 {
  width: 490px;
}
table .cell-500 {
  width: 500px;
}
table .cell-510 {
  width: 510px;
}
table .cell-520 {
  width: 520px;
}
table .cell-530 {
  width: 530px;
}
table .cell-540 {
  width: 540px;
}
table .cell-550 {
  width: 550px;
}
table .cell-560 {
  width: 560px;
}
table .cell-570 {
  width: 570px;
}
table .cell-580 {
  width: 580px;
}
table .cell-590 {
  width: 590px;
}
table .cell-600 {
  width: 600px;
}
/* Phones & Small Tablets   =========================*/
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  table th,
  table td {
    padding: 12px 12px 12px 6px;
  }
  table th.hide-mobile,
  table td.hide-mobile {
    display: table-cell;
  }
}
/* iPad Landscape          ========================*/
@media only screen and (min-width: 1024px) {
  table th.hide-tablet,
  table td.hide-tablet {
    display: table-cell;
  }
}
/* Desktop                 ========================*/
@media only screen and (min-width: 1280px) {
  table th.show-desktop,
  table td.show-desktop {
    display: table-cell;
  }
}
/* Content Grid         ==================================*/
.content-grid {
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  /* Sortable Heading ***/
  /* Content Styles ***/
  /* Content wrapping ***/
}
.content-grid thead th {
  background-color: #F5F5F5;
}
.content-grid tr:not(:last-child) td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.content-grid td .button:not(:last-child) {
  margin-right: 5px;
}
.content-grid .cell-sort {
  cursor: pointer;
  user-select: none;
}
.content-grid .cell-sort:not(:last-child):last-of-type {
  padding-right: 0;
}
.content-grid .cell-sort-label {
  position: relative;
  display: inline-block;
  padding-right: 12px;
}
.content-grid .cell-sort-label:before,
.content-grid .cell-sort-label:after {
  display: block;
  content: '';
  right: 0;
  position: absolute;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  width: 0;
  height: 0;
}
.content-grid .cell-sort-label:before {
  top: 4px;
  border-bottom: 4px solid #BABBBD;
}
.content-grid .cell-sort-label:after {
  top: 10px;
  border-top: 4px solid #BABBBD;
}
.content-grid .cell-sort-asc,
.content-grid .cell-sort-desc {
  color: #00B398;
}
.content-grid .cell-sort-asc .cell-sort-label,
.content-grid .cell-sort-desc .cell-sort-label {
  color: #00B398;
}
.content-grid .cell-sort-asc .cell-sort-label:before,
.content-grid .cell-sort-desc .cell-sort-label:before {
  top: 6px;
  border-bottom-width: 6px;
}
.content-grid .cell-sort-asc .cell-sort-label:after,
.content-grid .cell-sort-desc .cell-sort-label:after {
  top: 6px;
  border-top-width: 6px;
}
.content-grid .cell-sort-asc .cell-sort-label:before {
  border-bottom-color: transparent;
}
.content-grid .cell-sort-asc .cell-sort-label:after {
  border-top-color: #00B398;
}
.content-grid .cell-sort-desc .cell-sort-label:before {
  border-bottom-color: #00B398;
}
.content-grid .cell-sort-desc .cell-sort-label:after {
  border-top-color: transparent;
}
.content-grid .status-label {
  margin-left: 5px;
  font-size: 10px;
  padding: 4px 8px;
}
.content-grid .text-link {
  display: inline-block;
  font-family: 'DinMedium';
}
.content-grid .text-user {
  color: #989A9C;
  display: block;
  font-size: 12px;
}
.content-grid .text-user span {
  color: #53565A;
}
.content-grid .text-details {
  font-size: 12px;
  color: #989A9C;
}
.content-grid .text-price,
.content-grid .text-heading {
  display: block;
  font-family: 'DinBold';
}
.content-grid .text-price-old {
  display: block;
  color: #757876;
  font-style: 12px;
  text-decoration: line-through;
  font-family: 'DinBold';
}
.content-grid .text-price {
  font-size: 16px;
}
.content-grid .text-reference {
  text-overflow: ellipsis;
  overflow: hidden;
}
.content-grid .cell-wrapper:not(.hide-mobile) {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.content-grid .align-right .cell-wrapper:not(.hide-mobile) {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.content-grid .align-center .cell-wrapper:not(.hide-mobile) {
  -webkit-justify-content: center;
  justify-content: center;
}
/* Phones & Small Tablets   =========================*/
@media only screen and (max-width: 767px) {
  .content-grid .text-heading .status-label {
    font-family: 'DinBook';
  }
}
/* iPad Portrait              ========================*/
@media only screen and (min-width: 768px) {
  .content-grid {
    table-layout: fixed;
    /* Content Styles ***/
  }
  .content-grid thead th:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .content-grid thead th:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .content-grid tr {
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  }
  .content-grid tr:hover {
    background-color: rgba(255, 240, 208, 0.25);
  }
  .content-grid th:first-child,
  .content-grid td:first-child {
    padding-left: 14px;
  }
  .content-grid th:last-child,
  .content-grid td:last-child {
    padding-right: 14px;
  }
  .content-grid td {
    vertical-align: middle;
    word-wrap: break-word;
  }
  .content-grid .popover-icon {
    margin-top: -3px;
    width: 18px;
    height: 18px;
  }
  .content-grid .text-user {
    font-size: 14px;
  }
  .content-grid .text-product {
    font-family: 'DinLight';
    padding-right: 5px;
    font-size: 18px;
    line-height: 1;
  }
  .content-grid .text-product.color-grey {
    padding-right: 23px;
  }
  .content-grid .text-price {
    font-family: 'DinLight';
    font-size: 18px;
    line-height: 1;
  }
}
/* iPad Portrait              ========================*/
@media only screen and (max-width: 1023px) {
  .content-grid .row-actions {
    width: 80px;
  }
  .content-grid .row-actions .button {
    width: 100%;
  }
  .content-grid .row-actions .button:not(:last-child) {
    margin: 0 0 5px 0;
  }
}
/* Content Grid - Compact     ============================*/
.content-grid-compact td {
  padding: 4px 7px 4px 2px;
}
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  .content-grid-compact td {
    padding: 6px 6px 6px 3px;
  }
}
/* Content Grid - Pagination  ============================*/
.content-pagination {
  margin: 0 auto;
  padding: 20px 0;
  font-family: 'DinMedium';
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.content-pagination span {
  line-height: 22px;
  padding: 4px 7px;
  margin-left: -1px;
}
.content-pagination a {
  text-decoration: none;
  line-height: 22px;
  padding: 4px 14px;
  margin-left: -1px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.content-pagination a:hover:not(.active) {
  background-color: #DDDDDE;
}
.content-pagination a.active {
  color: #FFFFFF;
  background-color: #009FE3;
}
.content-pagination a:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.content-pagination a:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
/* Content Grid - Query Details  =========================*/
.content-grid .query-row,
.content-grid .row-open {
  background-color: #FFFCF4;
}
.content-grid .query-row:hover,
.content-grid .row-open:hover {
  background-color: #FFFCF4;
}
.content-grid .row-open td {
  border-bottom: none !important;
}
/* Content Grid - Products  ==============================*/
.products-grid th,
.products-grid td {
  padding: 6px 9px 6px 3px;
  vertical-align: middle;
  word-wrap: break-word;
}
.products-grid th.favorites-cell,
.products-grid td.favorites-cell {
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
}
.products-grid th.favorites-cell .icon-star,
.products-grid td.favorites-cell .icon-star {
  cursor: pointer;
  margin: 0 auto;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  width: 20px;
  height: 20px;
}
.products-grid td.favorites-cell .icon-star:hover {
  stroke: #FFB614;
}
.products-grid td.favorites-cell .icon-star.favorite {
  stroke: #FFB614;
  fill: #FFD372;
}
.products-grid td.favorites-cell .icon-star.favorite:hover {
  stroke: #BABBBD;
  fill: none;
}
.products-grid .enquiry-row > th,
.products-grid .enquiry-row > td {
  padding: 0;
}
.products-grid .popover-icon {
  margin-left: 5px;
  width: 14px;
  height: 14px;
}
.products-grid .button-small {
  margin-left: 5px;
}
.products-grid .icon-document,
.products-grid .icon-info {
  margin-right: 5px;
  width: 14px;
  height: 14px;
}
.products-grid .form-checkbox,
.products-grid .form-radio {
  min-height: 20px;
}
.products-grid .form-checkbox .indicator,
.products-grid .form-radio .indicator {
  top: 0;
}
.products-grid.residential-products-grid tr.selected td {
  background-color: rgba(0, 159, 227, 0.05);
}
.products-grid.residential-products-grid tr.selected .label {
  color: #009FE3;
}
.products-grid.commercial-products-grid tr.selected td {
  background-color: rgba(135, 24, 157, 0.05);
}
.products-grid.commercial-products-grid tr.selected .label {
  color: #87189D;
}
.products-grid.commercial-products-grid .form-checkbox input:checked + .indicator,
.products-grid.commercial-products-grid .form-radio input:checked + .indicator {
  background-color: #87189D;
}
.products-grid.discount-grid tr.selected > td {
  background-color: rgba(230, 0, 126, 0.05);
}
.products-grid.discount-grid tr.selected .label {
  color: #E6007E;
}
.products-grid.discount-grid .form-checkbox input:checked + .indicator,
.products-grid.discount-grid .form-radio input:checked + .indicator {
  background-color: #E6007E;
}
.enquiry-grid {
  margin: 10px 0 10px 40px;
  padding: 10px;
  border: 0;
  width: calc(100% - 40px);
}
.enquiry-grid tr:last-child td {
  border-bottom: none;
  padding-bottom: 0;
}
.discount-grid .form-checkbox .indicator {
  top: 10px;
}
.discount-grid .discount-row {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.discount-grid .discount-row .cell-wrapper .text-link {
  margin-left: auto;
  display: none;
}
.discount-grid .discount-row.selected .cell-wrapper .text-link {
  display: inline-block;
}
.discount-grid td {
  vertical-align: top;
}
.discount-grid td[colspan] {
  padding-bottom: 0;
}
.discount-grid tr:last-child td {
  border-bottom: 0;
}
.discount-grid tr:not(.discount-row) td[colspan] {
  padding-right: 0;
}
.discount-grid table td:first-child,
.discount-grid table th:first-child {
  padding-left: 0;
}
/* iPad Landscape         ========================*/
@media only screen and (min-width: 1024px) {
  .products-grid th,
  .products-grid td {
    padding: 9px 12px 9px 6px;
  }
  .products-grid th.favorites-cell,
  .products-grid td.favorites-cell {
    padding: 0;
  }
  .discount-grid .discount-row td {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .discount-grid .discount-row td[colspan] {
    padding-bottom: 10px;
  }
}
/* Sidebar Grid         ==================================*/
.sidebar-grid {
  table-layout: fixed;
}
.sidebar-grid td {
  vertical-align: middle;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.sidebar-grid tr:nth-child(odd) {
  background-color: #F5F5F5;
}
/* Order Summary Grid   ==================================*/
.order-summary-grid {
  margin: 7px 0 14px;
  table-layout: fixed;
}
.order-summary-grid td {
  padding-bottom: 0;
}
.order-summary-grid td:first-child {
  padding-left: 14px;
}
.order-summary-grid td:last-child {
  padding-right: 14px;
}
.order-summary-grid .label {
  display: block;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  color: #989A9C;
}
.order-summary-grid .value {
  font-family: 'DinBold';
  overflow-wrap: break-word;
}
.order-summary-grid .wrap-text {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* Core                 ==================================*/
label {
  font-family: 'DinMedium';
  cursor: pointer;
  display: table;
}
.label-required {
  color: #E90027;
}
input,
select,
textarea {
  font-family: 'DinBook';
  width: 100%;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
input[readonly],
select[readonly],
textarea[readonly],
input[disabled],
select[disabled],
textarea[disabled] {
  cursor: default;
}
input[disabled],
select[disabled],
textarea[disabled] {
  box-shadow: none;
  border: none;
}
input,
textarea {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: 'DinLight';
  font-style: italic;
  color: #989A9C;
  /*padding-top: 2px;*/
  /*opacity: 1;*/
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  font-family: 'DinLight';
  font-style: italic;
  color: #989A9C;
  /*padding-top: 2px;*/
  /*opacity: 1;*/
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  font-family: 'DinLight';
  font-style: italic;
  color: #989A9C;
  /*padding-top: 2px;*/
  /*opacity: 1;*/
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-family: 'DinLight';
  font-style: italic;
  color: #989A9C;
  /*padding-top: 2px;*/
  /*opacity: 1;*/
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
input:input-placeholder,
textarea:input-placeholder {
  font-family: 'DinLight';
  font-style: italic;
  color: #989A9C;
  /*padding-top: 2px;*/
  /*opacity: 1;*/
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
input:focus,
textarea:focus {
  box-shadow: 0 0 3px 1px #99D9F4, inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  border-color: #99D9F4;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0;
}
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  opacity: 0;
}
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  opacity: 0;
}
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0;
}
input:focus:input-placeholder,
textarea:focus:input-placeholder {
  opacity: 0;
}
input {
  height: 45px;
  padding: 0 12px;
}
input[type=search] {
  border-radius: 25px;
  padding: 0 24px;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
}
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}
input[type=file] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
input[type=file] i {
  display: none;
}
input[type=checkbox],
input[type=radio] {
  box-shadow: none;
  width: auto;
  height: auto;
}
input::-ms-clear {
  display: none;
}
textarea {
  padding: 12px;
  resize: none;
}
select {
  padding: 0 12px;
  line-height: 45px;
  cursor: pointer;
  color: inherit;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 100%;
  height: 45px;
}
/* Form Elements - Colors ***/
.form-control-grey {
  background-color: #F5F5F5;
}
.form-control-white {
  background-color: #FFFFFF;
}
.form-control-transparent {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.form-control-transparent:active,
.form-control-transparent:focus {
  box-shadow: none;
  border: none;
}
.form-control-datepicker input {
  font-family: 'DinBook';
  border-radius: unset;
  box-shadow: none;
  box-sizing: border-box;
  height: 45px;
}
.form-control-datepicker label {
  font-family: 'DinBook';
  font-size: 11px;
  color: #BABBBD;
  text-transform: uppercase;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
.form-control-datepicker fieldset {
  display: none;
}
.form-control-datepicker .MuiInputBase-root {
  background-color: #FFFFFF;
  width: 160px;
}
.form-control-datepicker .MuiInputBase-root.Mui-focused {
  box-shadow: 0 0 3px 1px #99D9F4, inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  border-color: #99D9F4;
  outline: none;
}
.form-control-datepicker .MuiInputBase-input {
  font-size: 14px;
}
.form-control-datepicker .MuiFormLabel-root.Mui-focused {
  color: #BABBBD;
  font-family: 'DinBook';
  -webkit-transform: translate(14px, 0px) scale(0.75);
  -moz-transform: translate(14px, 0px) scale(0.75);
  -ms-transform: translate(14px, 0px) scale(0.75);
  transform: translate(14px, 0px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.form-control-error-msg {
  /*box-shadow: inset 1px 1px 1px rgba(0,0,0,0.30), 0 0 0 1px lighten(@stsRed, 30);*/
  color: #E83553;
  /*background-color: @alertRedPale;*/
}
.form-control-error {
  /*box-shadow: inset 1px 1px 1px rgba(0,0,0,0.30), 0 0 0 1px lighten(@stsRed, 30);*/
  border-color: #E83553;
  background-color: #FDCFD7;
}
.form-control-warning {
  /*box-shadow: inset 1px 1px 1px rgba(0,0,0,0.30), 0 0 0 1px @orange20;*/
  border-color: #FFF0D0;
  background-color: #FFF0D0;
}
.form-control-success {
  /*box-shadow: inset 1px 1px 1px rgba(0,0,0,0.30), 0 0 0 1px @green20;*/
  border-color: #CCEAD7;
  background-color: #CCEAD7;
}
.form-control-options {
  padding: 14px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.form-control-options label {
  font-family: 'DinBook';
}
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  /* Form Elements - Sizes ***/
  .form-control-10 {
    width: 10%;
  }
  .form-control-20 {
    width: 20%;
  }
  .form-control-30 {
    width: 30%;
  }
  .form-control-40 {
    width: 40%;
  }
  .form-control-50 {
    width: 50%;
  }
  .form-control-60 {
    width: 60%;
  }
  .form-control-70 {
    width: 70%;
  }
  .form-control-80 {
    width: 80%;
  }
  .form-control-90 {
    width: 90%;
  }
  .form-control-100 {
    width: 100%;
  }
}
/* Form Elements - Additional Elements ***/
.form-control-description {
  display: inline-block;
  font-size: 13px;
  font-style: italic;
  margin-top: 5px;
}
.form-control-description:not([class*="alert-"]) {
  color: #989A9C;
}
/* Checkbox & Radio     ==================================*/
.form-checkbox,
.form-radio {
  display: table;
  position: relative;
  text-align: left;
  margin: 0;
  min-height: 30px;
}
.form-checkbox input,
.form-radio input,
.form-checkbox .indicator,
.form-radio .indicator {
  position: absolute;
  top: 5px;
  left: 0;
  width: 16px;
  height: 16px;
}
.form-checkbox input,
.form-radio input {
  opacity: 0;
  cursor: pointer;
  pointer-events: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
.form-checkbox .indicator,
.form-radio .indicator {
  display: block;
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.4), inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
.form-checkbox .indicator:before,
.form-radio .indicator:before {
  content: '';
  position: absolute;
}
.form-checkbox .label,
.form-radio .label {
  display: table-cell;
  vertical-align: middle;
  padding-left: 24px;
}
.form-checkbox input:checked + .indicator,
.form-radio input:checked + .indicator {
  background-color: #009FE3;
}
.form-checkbox.disabled,
.form-radio.disabled {
  opacity: .4;
}
.form-checkbox .indicator:before {
  top: 7px;
  left: 3px;
  width: 2px;
  height: 2px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.form-checkbox input:checked + .indicator:before {
  box-shadow: 2px 0 0 #FFFFFF, 4px 0 0 #FFFFFF, 4px -2px 0 #FFFFFF, 4px -4px 0 #FFFFFF, 4px -6px 0 #FFFFFF;
}
.form-radio .indicator {
  border-radius: 20px;
}
.form-radio .indicator:before {
  top: 5px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 7px;
}
.form-radio input:checked + .indicator:before {
  background-color: #FFFFFF;
}
/* On / Off Switch      ==================================*/
.form-toggle-option {
  padding: 5px 0;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.form-toggle-option:not(:last-child) {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.form-toggle-label {
  line-height: 1;
  margin: 0;
  max-width: calc(100% - 54px);
}
.form-toggle-button {
  position: relative;
  display: block;
  margin: 0 0 0 auto;
  width: 46px;
  height: 26px;
}
.form-toggle-button input {
  cursor: pointer;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.15);
  background-color: #F5F5F5;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  width: 100%;
  height: 100%;
}
.form-toggle-button input::-ms-check {
  display: none;
}
.form-toggle-button input:checked {
  background-image: linear-gradient(-180deg, #008C0E 0%, #47B543 100%);
  background-color: #1c9c23;
}
.form-toggle-button .toggle-indicator {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 9;
  background-color: #FFFFFF;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4), 0 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  width: 18px;
  height: 16px;
}
.form-toggle-button .toggle-line,
.form-toggle-button .toggle-line:before,
.form-toggle-button .toggle-line:after {
  display: block;
  position: absolute;
  background-color: #DDDDDE;
  width: 1px;
  height: 8px;
}
.form-toggle-button .toggle-line {
  top: 4px;
  left: 6px;
}
.form-toggle-button .toggle-line:before,
.form-toggle-button .toggle-line:after {
  content: '';
  position: absolute;
  top: 0;
}
.form-toggle-button .toggle-line:before {
  left: 2px;
}
.form-toggle-button .toggle-line:after {
  left: 4px;
}
.form-toggle-button .toggle-text {
  position: absolute;
  top: 5px;
  left: 60px;
  white-space: nowrap;
}
.form-toggle-button input:checked + .toggle-indicator {
  -webkit-transform: translateX(18px);
  transform: translateX(18px);
}
/* Dropdown             ==================================*/
.form-dropdown {
  position: relative;
}
.form-dropdown select {
  padding-right: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
.form-dropdown select::-ms-expand {
  display: none;
}
.form-dropdown:after {
  display: block;
  content: '';
  position: absolute;
  top: 22px;
  right: 10px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #19191B;
  width: 0;
  height: 0;
}
.form-dropdown.form-masked-label:after {
  top: 21px;
}
/* Masked Label         ==================================*/
.form-masked-label {
  position: relative;
}
.form-masked-label label:not(.option) {
  position: absolute;
  top: 9px;
  left: 12px;
  font-family: 'DinBook';
  font-size: 11px;
  color: #BABBBD;
  text-transform: uppercase;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
.form-masked-label input {
  padding-top: 12px;
}
.form-masked-label input:placeholder-shown {
  padding-top: 0;
}
.form-masked-label input:placeholder-shown:focus {
  padding-top: 12px;
}
.form-masked-label select {
  padding-top: 6px;
}
.form-masked-label input:placeholder-shown + label {
  opacity: 0;
}
.form-masked-label input:focus + label {
  opacity: 1;
}
.form-masked-label.entry-search label:not(.option) {
  left: 24px;
}
/* Microsoft Edgle only  =============================*/
@supports (-ms-ime-align:auto) {
  .form-masked-label input,
  .form-masked-label select {
    padding-top: 16px;
  }
}
/* IE10+            ==================================*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .form-masked-label {
    /*input {
                &:-ms-input-placeholder {
                    color: @white;
                }

                &:focus {
                    &:-ms-input-placeholder {
                        color: @white;
                    }
                }
            }*/
  }
  .form-masked-label input,
  .form-masked-label select {
    padding-top: 16px;
  }
}
/* Radio Options        ==================================
    .radio-options {
        #flexbox > .parent(row wrap, '', center, '');

        label:not(.option) {
            display: block;
            width: 100%;
        }

        .option {
            position: relative;
            flex: 1 1 auto;
            margin-left: -1px;
            height: 45px;
            
            input {
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
                opacity: 0;
                z-index: 99;
                .square(100%);
            }   

            .label {
                text-align: center;
                font-size: @baseFontSize - 2;
                line-height: 45px;
                padding: 0 10px;
                background-color: @white;
                box-shadow: inset 0 0 0 1px rgba(0,0,0,.10);
                .transition();
                #flexbox > .parent(row wrap, center, center, '');
            }

            input:checked + .label {
                color: @white;
                background-color: @blue100;
            }
            
            &:first-of-type {
                margin-left: 0;
                
                .label { 
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }
            }

            &:last-of-type {
                .label {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }
        }
    }*/
/* File Upload          ==================================*/
.file-upload {
  position: relative;
  padding: 14px;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-align-items: center;
  align-items: center;
}
.file-upload .icon-download {
  margin-bottom: 7px;
  width: 32px;
  height: 32px;
}
.file-upload.disabled {
  opacity: .4;
}
.file-upload.disabled .file-upload-label {
  display: none;
}
.file-upload-label {
  font-family: 'DinLight';
  font-style: italic;
  text-align: center;
}
.file-upload-control {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.file-upload-list {
  list-style: none;
  margin: 0;
}
.file-upload-list li {
  font-family: 'DinBold';
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.file-upload-list li > .file-label {
  padding-right: 5px;
  max-width: calc(100% - 50px);
}
.file-upload-list li:not(:last-child) {
  margin-bottom: 7px;
}
.file-upload-list .icon-attachment {
  margin-right: 5px;
  width: 14px;
  height: 14px;
}
.file-upload-list .file-label {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.file-upload-list .progress-label {
  font-family: 'DinLight';
  font-size: 12px;
  width: 100%;
}
.file-upload-list .progress-label:not([class*="color-"]) {
  color: rgba(0, 0, 0, 0.4);
}
.file-upload-list .progress-bar {
  background-color: #F5F5F5;
  border-radius: 3px;
  width: 100%;
  height: 4px;
}
.file-upload-list .button {
  overflow: hidden;
  margin-right: 0 !important;
}
.file-upload-list .button.color-teal {
  margin-right: 5px !important;
  max-width: calc(100% - 35px);
}
.file-upload-list:not(:empty) {
  padding: 10px 0;
}
/* Common Form Components   ==============================*/
.entry:not(:last-child) {
  margin-bottom: 20px;
}
.entry .loading {
  opacity: .5;
  -webkit-filter: blur(0.2px);
  filter: blur(0.2px);
}
.entry .loader {
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin: 0 0 -7px -12px;
  z-index: 99;
}
.entry-break {
  padding-bottom: 30px;
  background: url('../img/border.png') 0 100% repeat-x;
  background-size: 14px;
}
.entry-break:first-of-type {
  margin-top: 20px;
}
.entry-break:not(:last-child) {
  margin-bottom: 30px;
}
/* User Registration - Captcha image ***/
.captcha img {
  margin-bottom: 20px;
}
/* Input with icon ***/
.form-control-icon {
  position: relative;
}
.form-control-icon input {
  padding-right: 55px;
}
.form-control-icon .button-icon {
  position: absolute;
  right: 0;
}
.form-control-icon .button-icon-small {
  bottom: 6px;
}
.form-control-icon .button-icon-regular {
  bottom: 2px;
}
.form-control-icon .button-icon-display {
  bottom: 0;
}
.form-control-icon > .icon {
  position: absolute;
  top: 13px;
  right: 12px;
}
.form-control-icon .icon {
  width: 20px;
  height: 20px;
}
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  .captcha {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-align-items: center;
    align-items: center;
  }
  .captcha img {
    margin: 0 20px 0 0;
  }
  .captcha input {
    width: calc(100% - 220px);
  }
  .entry.form-control-nolabel {
    padding-top: 20px;
  }
}
/* iPad Landscape          ========================*/
/* User Forms - General     ==============================*/
.user-form {
  padding: 40px 0;
  width: 100%;
  max-width: 280px;
}
.user-form .actions {
  text-align: center;
}
.user-form .button-green {
  min-width: 180px;
}
.user-form .text-button {
  display: table;
  margin: 20px auto 0;
}
.user-form-title {
  margin-bottom: 20px;
}
.user-form-title h1,
.user-form-title .h1-description {
  text-align: center;
}
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  .user-form {
    background-color: #FFFFFF;
    padding: 40px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    max-width: 720px;
  }
  .user-form-title {
    margin-top: 20px;
  }
}
/* Desktop                ========================*/
@media only screen and (min-width: 1280px) {
  .user-form {
    max-width: 960px;
  }
}
/* User Forms - Login / Reset Password     ===============*/
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  .login-form {
    width: 400px;
  }
}
/* User Forms - Registration   ===========================*/
.register-form .actions-checkbox .form-checkbox {
  margin-bottom: 20px;
}
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  .register-form {
    max-width: 540px;
  }
  .register-form .actions-checkbox {
    text-align: left;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-align-items: center;
    align-items: center;
  }
  .register-form .actions-checkbox .form-checkbox {
    margin-bottom: 0;
    max-width: calc(100% - 220px);
  }
  .register-form .button {
    margin: 0 0 0 auto;
  }
}
/* User Forms -  Onboard    ==============================*/
.onboard-form {
  max-width: 320px;
}
.onboard-form .entry-block:first-of-type {
  margin-top: 20px;
}
.onboard-form .entry-block-left {
  margin-bottom: 20px;
}
.onboard-form .entry-search {
  text-align: center;
}
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  .onboard-form {
    max-width: 720px;
  }
  .onboard-form .user-form-title {
    margin-bottom: 40px;
  }
  .onboard-form .entry-block {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .onboard-form .entry-block:first-of-type {
    margin-top: 40px;
  }
  .onboard-form .entry-block-left {
    width: 200px;
    margin: 0 40px 0 0;
    text-align: right;
  }
  .onboard-form .entry-block-right {
    width: calc(100% - 240px);
  }
}
/* Filters Form       ====================================*/
.filters-form {
  padding: 0 10px;
  background-color: #DDDDDE;
  width: 100%;
  z-index: 10;
}
.filters-form .entry-search .form-dropdown {
  position: absolute;
  top: 0;
  right: 0;
  width: 130px;
}
.filters-form .entry-search .form-dropdown .icon {
  position: absolute;
  top: 12.5px;
  left: 12px;
}
.filters-form .entry-search .form-dropdown label {
  left: 42px;
}
.filters-form .entry-search .form-dropdown select {
  padding-left: 42px;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.filters-form .entry-search .form-dropdown:after {
  right: 20px;
}
.filters-form .form-control-icon .button-icon {
  right: 14px;
}
.filters-form .form-control-icon .icon-clear {
  width: 14px;
  height: 14px;
}
.filters-form .actions {
  padding-left: 10px;
  margin-left: 10px;
  box-shadow: -1px 0 0 rgba(0, 0, 0, 0.1);
}
.filters-form .button-white:not(:active):not(:hover) {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}
.filters-form .content-wrapper {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
/* Phones & Small Tablets   =========================*/
@media only screen and (max-width: 767px) {
  .filters-form {
    position: absolute;
    top: 50px;
    left: 0;
    overflow: hidden;
    z-index: 20;
    width: 100%;
    height: 0px;
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  }
  .filters-form .entry {
    width: calc(50% - 5px);
    margin-bottom: 10px;
  }
  .filters-form .entry:nth-child(even):not(.entry-search) {
    margin-right: 10px;
  }
  .filters-form .entry.entry-search {
    width: 100%;
  }
  .filters-form .entry.hide-tablet {
    display: block;
  }
  .filters-form .content-wrapper {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .filters-form .actions {
    padding: 0;
    margin: 0 0 10px 0;
    box-shadow: none;
    width: 100%;
    text-align: center;
  }
  .filters-form .actions .button-icon {
    margin: 0 auto;
  }
  .filters-form.open {
    padding: 10px 10px 0 10px;
    height: auto;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  }
}
/* iPad Portrait    ==================================*/
@media only screen and (min-width: 768px) {
  .filters-form {
    display: block;
    padding: 10px 20px;
  }
  .filters-form .entry {
    max-width: 170px;
    margin: 0;
    flex: 1 1 auto;
  }
  .filters-form .entry.entry-search {
    max-width: 100%;
  }
  .filters-form .entry:not(:last-of-type) {
    padding-right: 10px;
    margin: 0 10px 0 0;
    box-shadow: 1px 0 0 rgba(0, 0, 0, 0.1);
  }
  .filters-form .entry:not(:last-of-type) > .icon {
    right: 22px;
  }
  .filters-form .entry-search .form-dropdown {
    right: 10px;
  }
}
/* Desktop          ==================================*/
@media only screen and (min-width: 1320px) {
  .filters-form {
    padding: 10px 0;
  }
}
/* Preferences Form   ====================================*/
.preferences-form {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.preferences-form .vcard {
  margin: 0;
  padding: 20px;
  width: 100%;
}
.preferences-form .vcard .label {
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-right: auto;
}
.preferences-form .vcard .value {
  font-family: 'DinMedium';
  overflow-wrap: break-word;
}
.preferences-form .vcard li {
  text-align: right;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.preferences-form .vcard li.vcard-edit {
  padding-top: 10px;
  text-align: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.preferences-fields {
  width: 100%;
  padding-bottom: 20px;
}
.preferences-fields .actions {
  text-align: center;
}
.preferences-fields .button-green {
  min-width: 180px;
}
.preferences-actions {
  padding: 10px;
}
.preferences-form-title {
  padding: 20px;
  text-align: center;
}
.preferences-form-content {
  padding: 0 20px;
}
.preferences-form-content > .vcard {
  border-radius: 3px;
  background-color: #F5F5F5;
}
/*.preferences-form-search {
        padding: 20px;
        border-radius: 3px;
        background-color: @grey;
        box-shadow: 0 0 2px 1px rgba(0,0,0,0.20);

        .entry-search {
            margin-left: -20px;
            padding: 0 20px 20px 20px;
            background: @border 0 100% repeat-x;
            background-size: 14px;
            #calc > #width > .add(100%, 40px);
        }
    }*/
/* Phones & Small Tablets   =========================*/
@media only screen and (max-width: 767px) {
  .preferences-fields:not(:last-of-type) {
    background-image: url('../img/border.png');
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 14px;
  }
}
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  .preferences-form {
    padding: 40px;
  }
  .preferences-fields {
    background-color: #FFFFFF;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    max-width: 640px;
  }
  .preferences-fields:not(:last-child) {
    margin-bottom: 20px;
  }
  .preferences-form-title {
    padding: 60px 40px 0 40px;
  }
  .preferences-form-content {
    padding: 40px;
  }
}
/* Core             ==================================*/
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  /*position: fixed;
        top: 50%;
        left: 50%;*/
  background-color: #FFFFFF;
  z-index: 9999;
  pointer-events: none;
  opacity: 0;
  height: auto;
  border-radius: 3px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0, 1.47, 0.565, 1.61), opacity 0.2s ease-out;
  transition: transform 0.2s cubic-bezier(0, 1.47, 0.565, 1.61), opacity 0.2s ease-out;
  transition-delay: 8ms;
  -webkit-font-smoothing: antialiased;
  -webkit-filter: blur(0);
  -webkit-transform: translate3d(-50%, -50%, 0) scale3d(0.7, 0.7, 0.7);
  transform: translate3d(-50%, -50%, 0) scale3d(0.7, 0.7, 0.7);
}
.modal .modal-inner {
  padding: 20px;
  max-width: 85vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.modal.modal-loading {
  text-align: center;
  min-width: 160px;
  padding: 20px;
}
.modal.modal-open {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}
.modal.modal-open:not(.modal-loading) {
  width: 85vv;
}
.modal.modal-closing {
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: transform .2s linear;
  -webkit-transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
  transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9990;
  display: block;
  pointer-events: auto;
  width: 100%;
  height: 100%;
}
.modal-overlay.modal-closing {
  -webkit-animation: fadeOut 0.2s cubic-bezier(0.1, 0.1, 0.25, 0.9) both;
  animation: fadeOut 0.2s cubic-bezier(0.1, 0.1, 0.25, 0.9) both;
}
.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #FFFFFF;
  border-radius: 50%;
  text-indent: -9999px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.modal-close .icon {
  stroke-width: 4;
  width: 10px;
  height: 10px;
}
.modal-close-slideout {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: transparent;
  text-indent: -9999px;
  cursor: pointer;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.modal-close-slideout .icon {
  stroke-width: 4;
  width: 10px;
  height: 10px;
}
.modal-title {
  text-align: center;
  padding-top: 14px;
  margin-bottom: 28px;
}
.modal-title h1,
.modal-title .h1-description,
.modal-title .h2-description {
  margin-bottom: 0;
}
/* iPad Portrait        =========================*/
@media only screen and (min-width: 768px) {
  .modal .modal-inner {
    height: auto;
  }
  .modal.modal-open:not(.modal-loading) {
    max-width: 75vv;
  }
}
/* iPad Landscape        =========================*/
@media only screen and (min-width: 1024px) {
  .modal .modal-inner {
    max-height: 80vh;
  }
  .modal.modal-open:not(.modal-loading) {
    width: auto;
  }
}
/* Terms & Conditions       ==========================*/
.modal-terms-text ol {
  counter-reset: item;
}
.modal-terms-text ol > li {
  position: relative;
  display: block;
  counter-increment: item;
  margin-bottom: 7px;
}
.modal-terms-text ol > li:before {
  content: counters(item, ".") ".";
  position: absolute;
  top: 0;
  left: -42px;
  width: 42px;
  padding-right: 14px;
  text-align: right;
}
.modal-terms-text > ol {
  margin-left: 42px;
}
.modal-terms-text > ol > li h2 {
  margin-bottom: 7px;
}
.modal-terms-text > ol > li:before {
  font-family: 'DinLight';
  top: -3px;
  font-size: 20px;
}
.modal-terms-text ul {
  margin-left: 28px;
  list-style-type: disc;
}
.modal-terms-text p {
  margin-bottom: 7px;
}
/* iPad Portrait        =========================*/
@media only screen and (min-width: 768px) {
  .modal-terms-title {
    padding: 20px 20px 0 20px;
  }
}
/* Form             ==================================*/
.modal-form {
  padding: 20px;
}
.modal-form .actions {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
/* Confirmation     ==================================*/
.modal-confirmation {
  width: 386px;
  padding: 20px;
  max-width: 85vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  /*h1 {
            font-size: @baseFontSize + 4;
        }*/
}
.modal-confirmation h1,
.modal-confirmation .h1-description {
  text-align: center;
}
.modal-confirmation .h1-description {
  margin-bottom: 0;
}
/* Core             ==================================*/
.popover {
  position: absolute;
  top: -16px;
  left: 24px;
  background-color: #FFFFFF;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  width: 280px;
  cursor: default;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.popover ul {
  padding: 14px 0;
  margin-bottom: 14px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
}
.popover li {
  text-align: left;
  list-style: none;
  line-height: 1;
  padding: 0 14px;
}
.popover li span {
  display: block;
  color: #989A9C;
  font-size: 12px;
}
.popover li.last {
  color: #989A9C;
  font-size: 12px;
  text-align: center;
  padding-top: 7px;
}
.popover li:not(:last-child) {
  padding-bottom: 7px;
  margin-bottom: 7px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.popover .button {
  margin: 0 auto 14px;
}
.popover:after {
  content: '';
  position: absolute;
  top: 10px;
  left: -12px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #FFFFFF;
  width: 0px;
  height: 0px;
}
.popover.popover-products {
  right: 24px;
  left: auto;
}
.popover.popover-products:after {
  right: -24px;
  left: auto;
  border-right-color: transparent;
  border-left: 12px solid #FFFFFF;
}
.popover.popover-description {
  padding: 14px;
}
.popover.popover-loading {
  text-align: center;
  width: 180px;
  padding: 14px;
}
.popover-icon {
  position: relative;
  cursor: help;
}
.popover-icon .icon {
  width: 100%;
  height: 100%;
}
.popover-icon:hover .popover {
  visibility: visible;
  opacity: 1;
}
/* Packages       ==========================*/
.popover-residential {
  color: #FFFFFF;
  background-color: #009FE3;
}
.popover-residential:after {
  border-right-color: #009FE3;
}
.popover-commercial {
  color: #FFFFFF;
  background-color: #87189D;
}
.popover-commercial:after {
  border-right-color: #87189D;
}
.popover-package {
  color: #FFFFFF;
  background-color: #E6007E;
}
.popover-package:after {
  border-right-color: #E6007E;
}
.btn-tooltip {
  position: absolute;
  top: -35px !important;
  left: -50px;
  background-color: #FFF0D0;
  color: black;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  z-index: 9999;
  line-height: 30px;
  height: 30px;
  width: 180px !important;
  cursor: default;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  text-shadow: none;
}
/* Components 			==================================*/
/* Core             ==================================*/
.navbar {
  padding: 12.5px 0;
  background: #F5F5F5;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  z-index: 3000;
  width: 100%;
  height: 50px;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
.navbar .content-wrapper {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}
.navbar-logo {
  position: absolute;
  top: 12.5px;
  left: 50%;
  margin-left: -94px;
  width: 188px;
  height: 25px;
}
.navbar-logo img {
  width: auto;
  height: 25px;
}
.navbar-toggle {
  position: absolute;
  top: 0;
  right: 0;
  text-indent: -9999px;
  background: transparent;
  z-index: 40;
  cursor: pointer;
  width: 50px;
  height: 50px;
}
.navbar-toggle span,
.navbar-toggle span:before,
.navbar-toggle span:after {
  content: '';
  display: block;
  position: absolute;
  cursor: pointer;
  background-color: #009FE3;
  border-radius: 25px;
  width: 22px;
  height: 3px;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
.navbar-toggle span {
  top: 16px;
  left: 13px;
}
.navbar-toggle span:before {
  top: 7px;
  left: 0;
}
.navbar-toggle span:after {
  top: 14px;
  left: 0;
}
.navbar-filters {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  width: 50px;
  height: 50px;
}
.navbar-filters.active .icon {
  stroke: #009FE3;
}
.navbar-actions .button:not(:last-child) {
  margin-right: 10px;
}
.navbar-actions.show-mobile {
  display: none;
}
.navbar-menu {
  margin: 12.5px 0 0 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}
.navbar-menu li {
  position: relative;
  list-style: none;
  text-align: center;
  font-family: 'DinBold';
  z-index: 30;
}
.navbar-menu li a {
  cursor: default;
}
.navbar-menu li a.active {
  background-color: #F5F5F5;
  margin-left: 1px;
}
.navbar-menu a:not(.button) {
  display: block;
  text-decoration: none;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
/* Phones & Small Tablets   =========================*/
@media only screen and (max-width: 767px) {
  .navbar.open {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    padding-bottom: 0;
    z-index: 999;
  }
  .navbar.open .navbar-actions {
    display: block;
    margin-bottom: 14px;
  }
  .navbar.open .navbar-filters {
    opacity: 0;
    visibility: hidden;
  }
  .navbar.open .navbar-toggle span {
    background: transparent;
  }
  .navbar.open .navbar-toggle span:before,
  .navbar.open .navbar-toggle span:after {
    top: 8px;
    background: #E90027;
  }
  .navbar.open .navbar-toggle span:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .navbar.open .navbar-toggle span:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .navbar.open .navbar-menu {
    margin: 0;
    opacity: 1;
    visibility: visible;
    width: 100%;
    height: auto;
    -webkit-animation: fadeIn 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9) both;
    animation: fadeIn 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9) both;
  }
  .navbar-menu {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .navbar-menu li {
    font-size: 16px;
    line-height: 42px;
    width: 100%;
    box-shadow: inset 0 -1px 0 #DDDDDE;
  }
  .navbar-menu li:first-child {
    box-shadow: inset 0 1px 0 #DDDDDE, inset 0 -1px 0 #DDDDDE;
  }
}
/* iPad Portrait    ==================================*/
@media only screen and (min-width: 768px) {
  .navbar {
    /*position: static;*/
    padding: 0;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    -webkit-transition: none;
    transition: none;
  }
  .navbar .navbar-logo {
    position: static;
    margin-left: 0;
  }
  .navbar .navbar-logo img {
    width: auto;
    height: 25px;
  }
  .navbar .content-wrapper {
    padding: 0 20px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .navbar-menu {
    margin: 0 0 0 10px;
    opacity: 1;
    visibility: visible;
    width: auto;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .navbar-menu li {
    line-height: 50px;
    min-width: 80px;
    box-shadow: inset 1px 0 0 0 #DDDDDE;
  }
  .navbar-menu li:not(.show-mobile) {
    display: list-item;
  }
  .navbar-menu li:last-child {
    box-shadow: inset 1px 0 0 0 #DDDDDE, inset -1px 0 0 0 #DDDDDE;
  }
  .navbar-menu a {
    padding: 0 10px;
  }
  .navbar-menu .badge {
    display: block;
  }
}
/* Desktop          ==================================*/
@media only screen and (min-width: 1320px) {
  .navbar .content-wrapper {
    padding: 0;
  }
}
/* IE10+            ==================================*/
/* Core             ==================================*/
.footer {
  text-align: center;
  padding: 20px 0;
  background: url('../img/border.png') 0 0 repeat-x;
  background-size: 14px;
  width: 100%;
}
.footer p {
  padding: 0 20px;
}
.footer-left {
  font-family: 'DinLight';
  margin-bottom: 16px;
}
.footer-left a {
  display: table;
  margin: 0 auto;
}
.footer-right {
  font-size: 13px;
}
.footer-actions {
  padding: 0 20px 20px 20px;
  margin-bottom: 10px;
  background: url('../img/border.png') 0 100% repeat-x;
  background-size: 14px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-align-items: center;
  align-items: center;
}
.footer-actions .button:first-of-type {
  margin-bottom: 20px;
}
/* iPad Portrait             ==================================*/
@media only screen and (min-width: 768px) {
  .footer {
    padding: 30px 20px;
    text-align: left;
    background-color: rgba(153, 217, 244, 0.3);
    height: 120px;
  }
  .footer p {
    padding: 0;
    margin-bottom: 0;
  }
  .footer .content-wrapper {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-align-items: center;
    align-items: center;
  }
  .footer-left {
    margin: 0 auto 0 0;
  }
  .footer-left a {
    display: inline;
    margin: 0;
  }
  .footer-right {
    text-align: right;
    margin-left: auto;
  }
  .footer-actions {
    display: none;
  }
}
/* iPad Landscape            ==================================*/
@media only screen and (min-width: 1024px) {
  .footer-left {
    font-size: 18px;
  }
}
/* Core             ==================================*/
.page-title {
  background: #FFFFFF url('../img/border.png') 0 100% repeat-x;
  background-size: 14px;
  padding: 14px 0;
  z-index: 20;
}
.page-title .content-wrapper {
  position: relative;
  padding: 0 10px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.page-title-menu {
  list-style: none;
  margin: 0;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
.page-title-menu .count {
  font-size: 16px;
  line-height: 20px;
}
.page-title-menu .text {
  font-size: 14px;
  line-height: 1;
}
.page-title-menu a {
  font-family: 'DinBold';
  text-decoration: none;
  padding: 9px 14px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(0, 0, 0, 0.1), inset 1px 0 0 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
}
.page-title-menu li:first-child a {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.page-title-menu li:last-child a {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(0, 0, 0, 0.1), inset 1px 0 0 0 rgba(0, 0, 0, 0.1), inset -1px 0 0 0 rgba(0, 0, 0, 0.1);
}
.page-title-menu li.active a {
  color: #FFFFFF;
  background-color: #009FE3;
  cursor: default;
}
.details-header {
  width: calc(100% - 40px);
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.details-header .date-badge,
.details-header h1 {
  margin-right: 7px;
}
.details-header .details-title {
  width: calc(100% - 31px);
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.page-title-actions {
  list-style: none;
  margin: 0;
}
/* Phones & Small Tablets   =========================*/
@media only screen and (max-width: 767px) {
  .page-title .cart-toggle {
    position: relative;
    margin-left: auto;
  }
  .page-title .cart-toggle .badge {
    position: absolute;
    top: -8px;
    right: -8px;
  }
  .page-title.fixed {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    z-index: 990;
    width: 100%;
    height: 50px;
  }
  .page-title-menu.hide-mobile {
    display: none;
  }
  .details-header .button-text-icon {
    position: absolute;
    top: -65px;
    left: 0;
    display: block;
    text-indent: -9999px;
    overflow: hidden;
    padding: 0;
    z-index: 40;
    width: 50px;
    height: 50px;
  }
  .details-header .button-text-icon .icon {
    position: absolute;
    top: 15px;
    left: 10px;
    margin: 0;
    stroke-width: 2px;
    width: 22px;
    height: 22px;
  }
  .details-header .button-text-icon:hover {
    box-shadow: none;
  }
  .details-header .button-text-icon:active {
    background: #DDDDDE;
    box-shadow: none;
  }
  .page-title-actions {
    position: fixed;
    bottom: -100%;
    left: 0;
    background-color: #F5F5F5;
    width: 100%;
    z-index: 999;
    box-shadow: 0 -1px 2px 1px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .page-title-actions li:not(.hide-mobile) {
    text-align: center;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .page-title-actions li:not(.hide-mobile):not(:last-child) {
    padding-bottom: 10px;
  }
  .page-title-actions .button {
    width: 50%;
  }
  .page-title-actions.open {
    bottom: 0;
  }
}
/* iPad Portrait    =================================*/
@media only screen and (min-width: 768px) {
  .page-title {
    background: #F5F5F5;
    height: 60px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    z-index: 40;
  }
  .page-title .content-wrapper {
    padding: 0 20px;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .page-title-menu {
    padding-left: 10px;
  }
  .page-title-menu .icon,
  .page-title-menu .count {
    display: block;
  }
  .page-title-menu .icon {
    margin-bottom: 3px;
    stroke: #009FE3;
    width: 17px;
    height: 17px;
  }
  .page-title-menu a {
    box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.1);
    padding: 0 14px;
  }
  .page-title-menu a:hover .icon {
    stroke: #E6007E;
  }
  .page-title-menu a:active .icon {
    stroke: #53565A;
  }
  .page-title-menu li {
    flex: 1 1 auto;
  }
  .page-title-menu li:first-child a {
    border-radius: 0;
  }
  .page-title-menu li:last-child a {
    border-radius: 0;
    padding-right: 0;
    box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.1);
  }
  .page-title-menu li.active {
    position: relative;
  }
  .page-title-menu li.active .icon {
    stroke: #989A9C;
  }
  .page-title-menu li.active a {
    color: #989A9C;
    background-color: transparent;
  }
  .page-title-menu li.active:after {
    content: '';
    position: absolute;
    bottom: -23px;
    left: 50%;
    margin-left: -12px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #F5F5F5;
    width: 0px;
    height: 0px;
  }
  .details-header {
    width: auto;
  }
  .details-header .date-badge {
    position: absolute;
    top: 0;
    left: 20px;
    margin: 0;
  }
  .details-header .button-text-icon {
    margin-top: -4px;
    padding: 0 0 0 18px;
  }
  .details-header .button-text-icon .icon {
    left: 0;
    margin-top: -8px;
  }
  .details-header.with-badge {
    padding-left: 40px;
  }
  .page-title-actions {
    margin: 0 10px 0 auto;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .page-title-actions li:not(:last-child) {
    margin-right: 10px;
  }
}
/* iPad Landscape   ================================*/
/* Desktop          ==================================*/
@media only screen and (min-width: 1320px) {
  .page-title .content-wrapper {
    padding: 0;
  }
  .details-header .date-badge {
    left: 0;
  }
}
/* IE10+            ==================================*/
/* Screens				==================================*/
/* Core             ==================================*/
.onboard-content {
  min-height: calc(100% - 40px);
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  .onboard-content {
    padding: 40px;
    min-height: calc(100% - 170px);
  }
  .onboard-content.long-form {
    padding-bottom: 80px;
  }
}
/* IE10+           ===============================*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .onboard-content {
    height: calc(100% - 170px);
    height: auto !important;
  }
}
/* Core                 ==================================*/
.content-block {
  position: relative;
  background: #FFFFFF url('../img/border.png') 0 100% repeat-x;
  background-size: 14px;
}
.content-block .content-block-form {
  overflow: hidden;
  height: auto;
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9) both;
  animation: fadeIn 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9) both;
}
.content-block-title {
  background: #FFFFFF url('../img/border.png') 0 100% repeat-x;
  background-size: 14px;
  padding: 7px 10px;
}
.content-block-title h2 {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 30px;
}
.content-block-title .icon-info {
  margin-left: 5px;
  stroke: #00B398;
  width: 14px;
  height: 14px;
}
.content-block-title .icon-arrow-down {
  margin-left: auto;
  stroke-width: 2;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 12px;
  height: 12px;
}
.content-block-title .button {
  margin-left: auto;
}
.content-block-title.fixed {
  position: fixed;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  z-index: 999;
  width: calc(100% - 40px);
}
.content-block-title.with-arrow {
  cursor: pointer;
}
.content-block-title:not(.show-mobile) {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.content-grid-toggle {
  list-style: none;
  margin: 0 0 0 auto;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
.content-grid-toggle li {
  font-family: 'DinBold';
  cursor: pointer;
  line-height: 20px;
  padding: 5px 12px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(0, 0, 0, 0.1), inset 1px 0 0 0 rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.content-grid-toggle li:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.content-grid-toggle li:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(0, 0, 0, 0.1), inset 1px 0 0 0 rgba(0, 0, 0, 0.1), inset -1px 0 0 0 rgba(0, 0, 0, 0.1);
}
.content-grid-toggle li.active {
  color: #FFFFFF;
  background-color: #009FE3;
  cursor: default;
}
.content-grid-toggle li.color-purple.active {
  background-color: #87189D;
}
.content-grid-toggle li.color-pink.active {
  background-color: #E6007E;
}
.content-block.collapsed .content-block-title .icon-arrow-down {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.content-block.collapsed .content-block-form {
  height: 0;
  padding: 0;
  -webkit-animation: fadeOut 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9) both;
  animation: fadeOut 0.3s cubic-bezier(0.1, 0.1, 0.25, 0.9) both;
}
/* Phones & Small Tablets   =========================*/
@media only screen and (max-width: 767px) {
  .overview-wrapper {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  .overview-wrapper #orders {
    order: 1;
  }
  .overview-wrapper #quotes {
    order: 2;
  }
  .overview-wrapper #queries {
    order: 3;
  }
  .content-block-grid {
    width: 100%;
    overflow: hidden;
  }
  .content-block-title {
    color: #FFFFFF;
    background-color: #53565A;
  }
  .content-block-title .icon-arrow-down {
    stroke: #FFFFFF;
  }
  .content-block-title.fixed {
    top: 50px;
  }
  h2.hide-mobile + .content-grid-toggle {
    margin: 0;
    width: 100%;
  }
  h2.hide-mobile + .content-grid-toggle li {
    flex: 1 1 auto;
  }
}
/* iPad Portrait    ==================================*/
@media only screen and (min-width: 768px) {
  .content-block {
    background: #FFFFFF;
    margin: 20px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  .content-block.collapsed .content-block-title {
    border-radius: 3px;
    background-image: none;
  }
  .content-block-title {
    padding: 14px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .content-block-title .count {
    font-size: 12px;
    line-height: 1.45;
    color: #989A9C;
    margin: 0 0 0 auto;
  }
  .content-block-title .button {
    margin: 0 0 0 10px;
  }
  .content-block-title.fixed {
    top: 0;
  }
  .content-block-grid {
    background: url('../img/blueprint-small.png') 100% 200px no-repeat;
    background-size: contain;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}
/* iPad Landscape    ==================================*/
/* Desktop          ==================================*/
@media only screen and (min-width: 1320px) {
  .content-block {
    margin: 20px auto;
  }
}
/* Order Details        ==================================*/
.details-aside {
  overflow: hidden;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
.details-aside.with-cart {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
}
/* Aside - Title ***/
.details-aside-title {
  padding: 10px;
  background-color: #53565A;
  width: 100%;
}
.details-aside-title h4 {
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.05em;
}
/* Aside - Content ***/
.details-aside-content {
  width: 100%;
}
/* Aside - Price ***/
.details-aside-price {
  border-radius: 3px;
  padding: 14px;
}
.details-aside-price .price-value {
  font-family: 'DinLight';
  font-size: 26px;
}
/* Aside - Cart ***/
.details-aside-cart {
  list-style: none;
  margin: 0;
}
.details-aside-cart li {
  padding: 6px 10px;
  line-height: 1.2;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.details-aside-cart li:not(:last-child) {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.details-aside-cart .product {
  width: calc(100% - 30px);
}
.details-aside-cart .product-name {
  display: block;
}
.details-aside-cart .product-price {
  display: block;
  color: #989A9C;
}
.details-aside-cart .button {
  margin-left: auto;
}
.details-aside-cart .icon-clear {
  stroke-width: 4;
  width: 10px;
  height: 10px;
}
/* Aside - Total Order Cost ***/
.details-aside-totals {
  list-style: none;
  margin: 0;
  background-color: #e8e8e8;
}
.details-aside-totals .label {
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
.details-aside-totals .value {
  margin-left: auto;
}
.details-aside-totals li {
  padding: 5px 10px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.details-aside-totals li.total {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'DinBold';
}
.details-aside-totals li.total .label {
  color: #53565A;
}
.details-aside-totals li.total:last-child .value {
  font-size: 18px;
}
.details-aside-totals li:not(:last-child) {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}
/* Aside - Order Completion Date ***/
.details-aside-date {
  padding: 10px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.details-aside-date .date-badge {
  margin-left: auto;
}
/* Aside - Order Actions ***/
.details-aside-action {
  padding: 14px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.details-aside-action .button:last-child:not(:first-child) {
  margin-left: 10px;
}
/* Aside - Order Address ***/
.details-aside-address {
  padding: 10px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
}
.details-aside-address .vcard {
  margin: 0;
  width: 100%;
}
.details-aside-address .vcard .label {
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-right: auto;
}
.details-aside-address .vcard li {
  text-align: right;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
/* Phones & Small Tablets   =========================*/
/* iPad Portrait    ==================================*/
@media only screen and (min-width: 768px) {
  .details-aside .file-upload,
  .details-aside .file-upload-list {
    margin: 10px;
  }
  .details-aside-column {
    display: block;
    background: #FFFFFF url('../img/blueprint-small.png') 100% 200px no-repeat;
    background-size: contain;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    width: calc(50% - 30px);
  }
  .details-aside-column:first-child {
    margin-left: 20px;
    margin-right: 10px;
  }
  .details-aside-column:last-child {
    margin-left: 10px;
    margin-right: 20px;
  }
  .details-aside-title:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .details-aside-price {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-align-items: center;
    align-items: center;
  }
}
/* Up to iPad Landscape    ============================*/
@media only screen and (max-width: 1023px) {
  .details-aside {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 100vh;
  }
  .details-aside .cart-toggle {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .details-aside .no-content {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-align-items: center;
    align-items: center;
    height: 100%;
  }
  .details-aside.with-cart {
    position: fixed;
    bottom: -100vh;
    left: 0;
    background-color: #F5F5F5;
    width: 100%;
    z-index: 999;
    display: block;
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  }
  .details-aside-action .button.show-mobile {
    margin-right: auto;
  }
  /* Open Cart ***/
  .details-aside.with-cart.open {
    bottom: 0;
    padding-bottom: 232px;
    height: 100vh;
    top: 50px;
  }
  .details-aside.with-cart.open .cart-toggle {
    position: absolute;
    top: 7px;
    right: 10px;
  }
  .details-aside.with-cart.open .details-aside-title {
    padding: 15px 10px;
  }
  .details-aside.with-cart.open .details-aside-totals,
  .details-aside.with-cart.open .details-aside-date,
  .details-aside.with-cart.open .details-aside-action {
    position: fixed;
    left: 0;
    width: 100%;
    overflow: hidden;
  }
  .details-aside.with-cart.open .details-aside-totals {
    box-shadow: 0 -1px 2px 1px rgba(0, 0, 0, 0.2);
  }
  .details-aside.with-cart.open .details-aside-date,
  .details-aside.with-cart.open .details-aside-action {
    background-color: #F5F5F5;
  }
  .details-aside.with-cart.open .details-aside-totals {
    bottom: 102px;
    height: 130px;
  }
  .details-aside.with-cart.open .details-aside-date {
    bottom: 58px;
    height: 44px;
  }
  .details-aside.with-cart.open .details-aside-action {
    bottom: 0;
    height: 58px;
  }
}
/* iPad Landscape    ==================================*/
@media only screen and (min-width: 1024px) {
  .details-wrapper {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .details-main {
    width: calc(100% - 300px);
  }
  .details-aside {
    display: block;
    margin: 20px 20px 20px 0;
    background: #FFFFFF url('../img/blueprint-small.png') 100% 200px no-repeat;
    background-size: contain;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    width: 280px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  .details-aside .details-aside-cart {
    max-height: 282px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .details-aside .details-aside-cart.with-address {
    max-height: 182px;
  }
  .details-aside.with-cart {
    display: block;
  }
  .details-aside.fixed {
    position: fixed;
    top: 60px;
    margin: 0;
    z-index: 999;
    max-height: calc(100vh - 40px);
  }
  .details-aside-column {
    padding: 0;
    width: 100%;
    background: transparent;
    box-shadow: none;
  }
  .details-aside-column:first-child,
  .details-aside-column:last-child {
    margin: 0;
  }
  .details-aside-column:nth-child(2) .details-aside-title:first-child {
    border-radius: 0;
  }
}
/* Desktop          ==================================*/
@media only screen and (min-width: 1320px) {
  .details-block {
    margin: 20px auto;
  }
  .details-main {
    margin-right: 20px;
  }
  .details-aside {
    margin-right: 0;
  }
}
/* New Order - Core     ==================================*/
.content-block-form .entry-block {
  margin-bottom: 20px;
}
.content-block-form .entry-block .entry:first-child {
  margin-bottom: 0;
}
.content-block-form .entry-block .form-control-description strong {
  color: #53565A;
  font-style: normal;
}
.content-block-form .products-disclaimer {
  font-style: italic;
  text-align: center;
  color: #757876;
  padding: 0 10px 10px;
}
.content-block-form .form-control-description {
  display: block;
  line-height: 22.5px;
  margin: 0;
}
.content-block-form:not(.no-padding) {
  padding: 14px;
}
.content-block-form.align-center {
  text-align: center;
}
/* Products Group - Wrapper ***/
.products {
  padding: 10px;
}
.products .product-alert {
  margin-left: 0;
  margin-right: 0;
}
.products.residential-products .products-grid-title:not(.favorites-title) {
  background-color: #009FE3;
}
.products.residential-products .products-group-tabs li {
  color: #009FE3;
}
.products.residential-products .products-group-tabs li.active {
  color: #FFFFFF;
  background-color: #009FE3;
}
.products.residential-products .products-group-tabs li.active .icon {
  stroke: #FFFFFF;
}
.products.commercial-products .products-grid-title:not(.favorites-title) {
  background-color: #87189D;
}
.products.commercial-products .products-group-tabs li {
  color: #87189D;
}
.products.commercial-products .products-group-tabs li.active {
  color: #FFFFFF;
  background-color: #87189D;
}
.products.commercial-products .products-group-tabs li.active .icon {
  stroke: #FFFFFF;
}
.products.discount-packages .discount-grid {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.products.discount-packages .discount-grid .text-package {
  padding-left: 26px;
}
/* Products Group Title - Initial ***/
.products-grid-title {
  padding: 0 10px;
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'DinBold';
  line-height: 32px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.products-grid-title.favorites-title {
  background-color: #FFB614;
}
/* Products Group - Initial ***/
.products-group:not(.favorites-group) {
  overflow: hidden;
  height: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
/* Products Group - Favorites ***/
.products-group.favorites-group {
  margin: 10px 0;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
/* Products Group - Expanded ***/
.products-group.expanded {
  height: auto;
  overflow: visible;
}
.products-group.expanded:not(.discount-packages) {
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
/* Products Group - Categories List ***/
.products-group-tabs {
  margin: 0;
  width: calc(100% + 5px);
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: space-around;
  align-content: space-around;
}
.products-group-tabs li {
  cursor: pointer;
  text-align: center;
  padding: 10px;
  margin: 5px 5px 5px 0;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
  flex: 1 1 auto;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-align-items: center;
  align-items: center;
}
/* Products Group - A-Z List ***/
.products-grid-tabs {
  list-style: none;
  margin: 0;
  padding: 5px;
  background-color: #DDDDDE;
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: space-around;
  align-content: space-around;
}
.products-grid-tabs li {
  display: inline-block;
  margin: 0;
  text-align: center;
  line-height: 30px;
  padding: 0;
  width: 20px;
  height: 28px;
}
.products-grid-tabs li:not(:last-child) {
  margin-right: 3px;
}
.products-grid-tabs li:not(.button) {
  color: #BABBBD;
}
/* Property Address ***/
.content-block-form.content-block-address {
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  padding: 0 14px;
}
.content-block-form.content-block-address .vcard .label {
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  display: block;
}
.content-block-form.content-block-address .vcard li {
  line-height: 1;
}
.content-block-form.content-block-address .vcard li.vcard-edit {
  padding-top: 10px;
  text-align: center;
}
.content-block-form.content-block-address .address-details {
  margin-top: 14px;
  margin-bottom: 14px;
}
.content-block-form.content-block-address .address-details .actions {
  text-align: center;
  padding-top: 10px;
  margin-top: 10px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.content-block-form.content-block-address .address-details:not(.alert) {
  padding: 14px;
  background-color: #F5F5F5;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.content-block-form.content-block-address .additional-details {
  margin-top: 14px;
  margin-bottom: 14px;
}
/* iPad Portrait    ==================================*/
@media only screen and (min-width: 768px) {
  .content-block-form {
    background: url('../img/blueprint-small.png') 100% 200px repeat;
    background-size: contain;
  }
  .content-block-form .entry-block {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .content-block-form .entry-block .entry:not(:first-child) {
    padding-left: 20px;
  }
  .content-block-form .products-disclaimer {
    padding: 28px 10%;
  }
  .content-block-form.align-center {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .products {
    padding: 20px;
  }
  .products-group-tabs {
    width: 100%;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }
  .products-group-tabs li {
    box-shadow: none;
    border-radius: 0;
    margin: 0;
  }
  .products-group-tabs li .icon {
    width: 24px;
    height: 24px;
  }
  .products-group-tabs li.active {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: 0 -1px 1px 1px rgba(0, 0, 0, 0.2);
  }
  .products-grid-tabs li {
    flex: 1 1 auto;
  }
  .content-block-address {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .content-block-address .address-details {
    margin-right: 20px;
    width: calc(45% - 20px);
  }
  .content-block-address .address-details:not(.alert) {
    width: calc(65% - 20px);
  }
  .content-block-address .additional-details {
    flex: 1 0 auto;
    width: 25%;
  }
}
/* iPad Landscape    ==================================*/
/* Desktop          ==================================*/
@media only screen and (min-width: 1320px) {
  .content-block-address .address-details {
    width: calc(35% - 20px);
  }
  .content-block-address .address-details:not(.alert) {
    width: calc(55% - 20px);
  }
}
/* New Order - Wizard   ==================================*/
.order-wizard-actions {
  padding: 10px;
}
.order-wizard-summary {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: center;
}
.order-wizard-totals {
  list-style: none;
  margin: 0;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05);
}
.order-wizard-totals .label {
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-right: 20px;
  max-width: 240px;
  flex: 1 1 auto;
}
.order-wizard-totals .value {
  margin-left: auto;
}
.order-wizard-totals li {
  padding: 5px 10px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.order-wizard-totals li.total {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'DinBold';
}
.order-wizard-totals li.total .label {
  color: #53565A;
}
.order-wizard-totals li.total:last-child .value {
  font-size: 18px;
}
.order-wizard-totals li:not(:last-child) {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.order-wizard-date {
  padding: 10px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-align-items: center;
  align-items: center;
}
/* Phones & Small Tablets   =========================*/
@media only screen and (max-width: 767px) {
  .order-wizard-actions {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .order-wizard-actions .button {
    width: 75%;
  }
  .order-wizard-actions .button:not(:last-child) {
    margin-bottom: 10px;
  }
}
/* iPad Portrait    ==================================*/
@media only screen and (min-width: 768px) {
  .order-wizard-actions {
    padding: 0 20px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .order-wizard-actions.actions-step-2 {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .order-wizard-actions.actions-step-2 .button:nth-child(2) {
    margin-left: auto;
  }
  .order-wizard-actions.actions-step-3 {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .order-wizard-actions.actions-step-3 .button:nth-child(2) {
    margin: 0 10px 0 auto;
  }
}
/* Desktop          ==================================*/
@media only screen and (min-width: 1320px) {
  .order-wizard-actions {
    padding: 0;
  }
}
/* Order Summary   ==================================*/
.order-summary {
  padding-top: 40px;
}
.order-summary-title {
  text-align: center;
}
.order-summary-title .h1-description {
  margin-bottom: 0;
}
.order-summary-title .status-label {
  color: #009FE3;
  font-family: 'DinBold';
  font-size: 12px;
}
.order-summary-date {
  margin: 28px auto;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-align-items: center;
  align-items: center;
}
.order-summary-block {
  background: url('../img/border.png') 0 0 repeat-x;
  background-size: 14px;
}
.order-summary-block .products-grid tr:hover {
  background-color: transparent;
}
.order-summary-block .products-grid tfoot {
  background-color: rgba(0, 0, 0, 0.05);
}
.order-summary-block .products-grid tfoot .label {
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
.order-summary-block .products-grid tfoot .total {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'DinBold';
}
.order-summary-block .products-grid tfoot .total .label {
  color: #53565A;
}
.order-summary-block .products-grid tfoot .total:last-child .text-price {
  font-size: 20px;
  font-family: 'DinBold';
}
.order-summary-block-title {
  background: rgba(0, 0, 0, 0.025) url('../img/border.png') 0 100% repeat-x;
  background-size: 14px;
  padding: 7px 14px;
}
.order-summary-block-title h2 {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 30px;
}
.order-summary-block-row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
.order-summary-block-row .order-summary-grid {
  width: 50%;
}
.order-summary-actions {
  padding: 14px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.order-summary-actions .button-blue {
  margin-right: 10px;
}
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  .summary-content {
    padding-top: 40px;
    min-height: calc(100% - 210px);
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    top: 0px;
  }
  .order-summary {
    background-color: #FFFFFF;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    max-width: 720px;
  }
  .order-summary-title {
    padding: 40px 40px 0;
  }
  .order-summary-title .h1-description .status-label {
    font-size: 14px;
  }
}
/* Core             ==================================*/
.query-details {
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
.query-conversation {
  text-align: center;
  padding: 14px;
}
.query-conversation > .button {
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 14px;
}
.query {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
}
.query:not(:last-child) {
  margin-bottom: 14px;
}
/* Query Details ***/
.query-person {
  font-family: 'DinBold';
  color: rgba(0, 0, 0, 0.4);
}
.query-text {
  padding: 10px;
  max-width: 75%;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}
.query-text ul,
.query-text ol {
  padding-left: 28px;
}
.query-date {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  padding-top: 7px;
}
/* Query Types ***/
.query-sts {
  text-align: left;
}
.query-sts .query-text {
  margin-right: auto;
  background-color: #F5F5F5;
}
.query-customer {
  text-align: right;
}
.query-customer .query-text {
  margin-left: auto;
  color: #FFFFFF;
  background-color: #33C2AD;
}
.query-action {
  padding: 20px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.query-action .button:not(:last-child) {
  margin-right: 5px;
}
.query-form {
  padding: 14px;
  background-color: #CCF0EA;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.query-form .file-upload .icon-download {
  stroke: rgba(0, 0, 0, 0.4);
}
.query-form .file-upload-list:not(:empty) {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.query-form .actions {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.query-form .actions .button:not(:last-child) {
  margin-right: 5px;
}
/* All Touch Devices   ===============================*/
@media only screen and (max-width: 1280px) {
  .query-form {
    position: relative;
  }
  .query-form .entry {
    margin-bottom: 0;
  }
  .query-form textarea {
    padding-right: 55px;
    height: 80px;
  }
  .query-form .file-upload {
    position: absolute;
    top: 40px;
    right: 20px;
    margin: 0;
    padding: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #F5F5F5;
    width: 45px;
    height: 78px;
  }
  .query-form .file-upload .icon-download {
    margin: 24px 0 0 0;
    width: 24px;
    height: 24px;
  }
  .query-form .file-upload-list:not(:empty) {
    margin-bottom: 10px;
  }
  .query-form .file-upload-label {
    display: none;
  }
}
/* Phones & Small Tablets   =========================*/
@media only screen and (max-width: 767px) {
  .query-details {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 60px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .query-info {
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px 50px 10px 10px;
    background-color: #FFFFFF;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 60px;
  }
  .query-info strong,
  .query-info span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .query-info span {
    color: #BABBBD;
  }
  .query-info .button {
    position: fixed;
    top: 10px;
    right: 10px;
  }
  .query-conversation {
    width: 100%;
  }
  .query-conversation h1 {
    margin-bottom: 1em;
  }
  .query-form,
  .query-action {
    margin-top: auto;
    width: 100%;
  }
}
/* iPad Portrait          ========================*/
@media only screen and (min-width: 768px) {
  .query-details {
    max-width: 640px;
    margin: 14px auto;
  }
  .query-conversation,
  .query-form {
    padding: 20px;
  }
  .query-text {
    max-width: 85%;
  }
}
/* Vendor				==================================*/
/* Core             ==================================*/
.datepicker-landscape {
  /* Typeface ***/
  /* Backgorund Colors ***/
  /* Colors ***/
  /* Calendar wrapper ***/
  /* Calendar ***/
  /* Calendar Left ***/
  /* Calendar Header ***/
  /* Calendar Header Title ***/
  /* Calendar Days of the Week ***/
  /* Calendar Body ***/
  /* Calendar Days Row ***/
  /* Calendar Day Cells ***/
  /* Calendar Buttons ***/
}
.datepicker-landscape [style*='border-radius: 2px;'] {
  border-radius: 3px !important;
}
.datepicker-landscape [style*='font-family: Roboto, sans-serif;'] {
  font-family: 'DinBook' !important;
}
.datepicker-landscape [style*='background-color: rgb(0, 151, 167);'] {
  background-color: #009FE3 !important;
}
.datepicker-landscape [style*='color: rgb(0, 188, 212);'] {
  color: #009FE3 !important;
}
.datepicker-landscape [style*='min-height: 330px; min-width: 479px; max-height: 1061px;'] {
  min-height: 375px !important;
}
.datepicker-landscape [style*='flex-direction: column; font-size: 12px; font-weight: 400; padding: 0px 8px;'] {
  font-size: 14px !important;
  padding: 0 !important;
}
.datepicker-landscape [style*='width: 165px; height: 330px; float: left; font-weight: 700; display: inline-block;'] {
  height: 375px !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
.datepicker-landscape [style*='display: flex; justify-content: space-between; background-color: inherit; height: 48px;'] {
  padding: 1px 0 !important;
  background-color: #F5F5F5 !important;
  height: 50px !important;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
.datepicker-landscape [style*='position: relative; overflow: hidden; height: 100%; font-size: 14px; font-weight: 500; text-align: center; width: 100%;'] {
  font-size: 18px !important;
  font-family: 'DinMedium' !important;
}
.datepicker-landscape [style*='display: flex; flex-direction: row; justify-content: space-between; font-weight: 500; height: 20px;'] {
  color: #989A9C !important;
  padding: 10px 0 5px 0!important;
  line-height: 20px !important;
  height: 35px !important;
}
.datepicker-landscape [style*='display: flex; flex-direction: row; justify-content: space-between; font-weight: 500; height: 20px;'] span {
  width: 40px !important;
}
.datepicker-landscape [style*='position: relative; overflow: hidden; height: 214px;'],
.datepicker-landscape [style*='display: flex; flex-direction: column; justify-content: flex-start; font-weight: 400; height: 228px; line-height: 2;'] {
  height: 240px !important;
}
.datepicker-landscape [style*='display: flex; flex-direction: row; justify-content: space-around; height: 34px; margin-bottom: 2px;'] {
  font-size: 16px !important;
  margin-bottom: 0 !important;
  height: 40px !important;
}
.datepicker-landscape [style*='display: flex; flex-direction: row; justify-content: space-around; height: 34px; margin-bottom: 2px;'] {
  /*font-size: @baseFontSize + 2 !important;*/
  /*margin-top: -1px !important;
            margin-bottom: 0 !important;*/
  /*> button {
                box-shadow: inset 0 0 0 1px @grey20 !important;

                &:first-of-type {
                    
                }

                &:not(:first-child) {
                    margin-left: -1px !important;
                }
            }*/
}
.datepicker-landscape [style*='display: flex; flex-direction: row; justify-content: space-around; height: 34px; margin-bottom: 2px;'] span,
.datepicker-landscape [style*='display: flex; flex-direction: row; justify-content: space-around; height: 34px; margin-bottom: 2px;'] button {
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  line-height: 40px !important;
}
.datepicker-landscape [style*='display: flex; flex-direction: row; justify-content: flex-end; margin: 0px; max-height: 48px;'] {
  height: 50px !important;
  max-height: 50px !important;
}
.datepicker-landscape [style*='display: flex; flex-direction: row; justify-content: flex-end; margin: 0px; max-height: 48px;'] button {
  margin: 0 10px 0 0 !important;
  height: 50px !important;
  line-height: 50px !important;
  font-family: 'DinMedium' !important;
}
.datepicker-landscape [style*='display: flex; flex-direction: row; justify-content: flex-end; margin: 0px; max-height: 48px;'] button:first-child:not(:last-child) {
  color: #757876 !important;
}
/* User Forms - General       ========================*/
/* iPad Portrait          ========================*/
/* Desktop                ========================*/
/* Print				==================================*/
@media only print {
  /* Core                 ==================================*/
  @page {
    margin: 1cm;
  }
  body {
    font-size: 10pt;
    background: transparent;
    overflow-x: visible;
  }
  body.no-scroll {
    overflow: visible;
  }
  table {
    table-layout: auto;
    border-collapse: collapse;
    border-spacing: 0;
  }
  table tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  table tr th,
  table tr td {
    page-break-after: auto;
    page-break-inside: avoid;
  }
  .content {
    background: transparent;
  }
  .navbar {
    position: relative;
    background: transparent;
    box-shadow: none;
    margin: 0 0 .5cm;
  }
  .navbar .navbar-logo {
    position: static;
    margin: 0;
  }
  .content-grid tr:not(:last-child) td {
    border-bottom: 1px solid #DDDDDE;
  }
  .content-grid th,
  .content-grid td {
    padding: 4px 7px 4px 1px;
    vertical-align: middle;
  }
  .content-grid td[colspan] {
    border-bottom: 2px solid #DDDDDE !important;
  }
  .content-grid td[colspan] .text-heading {
    font-size: 11pt;
  }
  .content-grid .text-price,
  .content-grid .text-heading {
    display: block;
    font-family: 'DinBold';
  }
  .content-grid .text-price {
    font-size: 10pt;
  }
  .discount-grid tr:last-child td {
    border-bottom: 0;
  }
  .date-badge {
    background-color: transparent !important;
    box-shadow: none !important;
    margin-left: 4pt;
    border: 1px solid #BABBBD;
    overflow: hidden;
    width: auto;
    height: 20pt;
  }
  .date-badge span {
    display: inline-block;
    padding: 0 4pt;
  }
  .date-badge .month,
  .date-badge .day {
    font-size: 10pt;
    line-height: 21pt;
    color: #757876 !important;
    background-color: transparent;
  }
  .date-badge .day {
    border-left: 1px solid #BABBBD;
    font-family: 'DinBook';
  }
  .file-upload-list .button {
    display: block;
    padding: 2px 6px;
    margin: 0 !important;
    border: 0;
    background: transparent;
    box-shadow: none;
    color: #757876 !important;
    border: 1px solid #DDDDDE;
    max-width: 100% !important;
  }
  .file-upload-list .button .icon {
    stroke: #757876;
  }
  .file-upload-list .button.button-text-icon {
    padding-left: 22px;
  }
  .file-upload-list:not(:empty) {
    padding: 0;
  }
  /* Hide from printing   ==================================*/
  .navbar-filters,
  .navbar-actions,
  .navbar-toggle,
  .navbar-menu,
  .user,
  .logged-user,
  .footer,
  .order-summary-actions,
  .version {
    display: none !important;
  }
  /* Order Summary        ==================================*/
  .order-summary {
    padding-top: 0;
  }
  .order-summary-title .status-label {
    color: #53565A;
    border-color: #BABBBD;
    background: transparent;
  }
  .order-summary-date {
    margin: 6pt auto .5cm;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .order-summary-block-title {
    padding: 4px 14px;
    border: 1px solid #BABBBD;
    border-radius: 3px;
    background: transparent;
  }
  .order-summary-block-title h2 {
    line-height: 20px;
  }
  .order-summary-block {
    background: transparent;
  }
  .order-summary-block .products-grid {
    margin-top: 7px;
  }
  .order-summary-block .products-grid tfoot {
    background: transparent;
  }
  .order-summary-block .products-grid tfoot .label {
    color: #989A9C;
  }
  .order-summary-block .products-grid tfoot .total {
    background: transparent;
    border-top: 2px solid #BABBBD;
  }
}
